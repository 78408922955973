<template>
  <section>
    <div class="container">
      <p class="title title-p text-center"> SHOP </p>
      <div class="row card-container">
        <div class="col-lg-4 col-6 shopcard my-2">
          <router-link :to="'/shop'"><!-- /tag-prodotto/nuovi-arrivi-->
            <img src="/img/shopcards/abbigliamento.jpg" alt="">
            <button class="primary-btn" type="button">ABBIGLIAMENTO</button>
          </router-link>
        </div>
        <div class="col-lg-4 col-6 shopcard my-2">
          <router-link :to="'/categoria-prodotto/accessori'">
            <img src="/img/shopcards/accessori.jpg" alt="">
            <button class="primary-btn" type="button">ACCESSORI</button>
          </router-link>
        </div>
        <!--<div class="col-lg-3 col-6 shopcard my-2">
          <router-link :to="'/categoria-prodotto/scarpe'">
            <img src="/img/shopcards/scarpe.jpg" alt="">
            <button class="primary-btn" type="button">SCARPE</button>
          </router-link>
        </div>-->
        <div class="col-lg-4 col-6 shopcard my-2">
          <router-link :to="'/categoria-prodotto/buoni-regalo'">
            <img src="/img/shopcards/gifregalo.png" alt="">
            <button class="primary-btn" type="button">BUONI REGALO</button>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Shop",
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

.card-container {
  position: relative;
  margin-bottom: 50px;
}

.card-container img {
  width: 100%;
  height: 100%;
}

.primary-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 80%;
}

.shopcard{
  min-height: 230px;
}

@media (max-width: 576px) {
  .primary-btn {
    font-size: 15px !important;
    padding: 10px 15px !important;
  }
}
</style>