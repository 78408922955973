<template>
  <div class="container">
      <div class="row title-p">
        <div class="col-lg-3 border-menu">
          <button v-if="datacomponent === false && ordercomponent === true" type="button" class="primary-btn" v-on:click="changeComponent">I miei ordini</button>
          <button v-if="datacomponent === true && ordercomponent === false" type="button" class="primary-btn" v-on:click="changeComponent">I miei dati</button>
          <button type="button" class="logout-button menu-text mt-2" v-on:click="logout()">ESCI DAL MIO PROFILO</button>
        </div>
        <div class="col-lg-9">
          <div v-if="datacomponent === false && ordercomponent === true">
            <ProfileData/>
          </div>
          <div v-if="datacomponent === true && ordercomponent === false">
            <Orders/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ProfileData from "@/components/ProfileData";
import Orders from "@/components/Orders";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Profilo",
  components: {Orders, ProfileData},
  data() {
    return {
      datacomponent: false,
      ordercomponent: true,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    changeComponent() {
      if (this.datacomponent === false && this.ordercomponent === true) {
        this.datacomponent = true;
        this.ordercomponent = false;
      }  else {
        this.datacomponent = false;
        this.ordercomponent = true;
      }
    }
  },
}
</script>

<style scoped>
.border-menu {
  border-right: 1px solid #8c8b8b;
  text-align: center;
}

template {
  background-color: #F3F2EE;
}

.primary-btn {
  width: 100%;
}

.logout-button {
  background-color: transparent;
  border: none;
  font-family: oswald;
}

.active { /* non cancellare */
display: none;
}

.title-p {
  margin-top: 30px;
}

</style>