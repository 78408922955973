<template>
  <router-link :to="'/prodotto/' + product.slug + '/'" class="product">
    <div class="product-image" v-if="product.images[0]" :class="!product.instock ? 'blurimg' : null"
         v-bind:style="{ backgroundImage: 'url(' + product.images[0]?.imageUrl + ')' }"></div>
    <div class="product-image" v-else v-bind:style="{ backgroundImage: 'url(' + base + 'img/placeholder.png)' }"></div>
    <!-- <img v-if="product.images[0]" class="product-thumb" :src="product.images[0]?.imageUrl"/>
    <img v-else class="image" :src="base + 'img/placeholder.png'" alt=""/>-->
    <div class="product__item__text">
      <p class="text mb-1 mt-2">{{ product.description }}</p>
      <h5 id="price" v-if="!discountPrice">{{ formatPrice(price) }} €</h5>
      <h5 v-else><span class="discounted">{{ formatPrice(price) }}</span> {{ formatPrice(discountPrice) }} €</h5>
    </div>
  </router-link>

</template>

<script>
import {PATH_APP,IVA} from "@/constants/constants";


export default {
  name: "SingleProduct",
  props: {
    product: {
      type: Object
    },
    outOfStockButton: {
      type: Boolean
    }
  },
  data() {
    return {
      price: null,
      idProduct: '',
      stock: [],
      variants: [],
      discountPrice: null,
      base: PATH_APP,
    }
  },
  computed: {
    userCountry(){
      return this.$store.getters.getUserCountry;
    }
  },
  mounted() {
    this.product.prices.filter((el) => el["type"] === 'price' ? this.price = el["value"] : this.discountPrice = el["value"]);
  },
  methods: {
    formatPrice(value) {
      if(this.userCountry && (this.userCountry === "Svizzera" || this.userCountry === "Regno Unito (UK)")){
        //value = (value - ((value / 100) * IVA)).toFixed(2);
        value = (value / 1.22).toFixed(2);
      }
      return Number(value)
          .toFixed(2)
          .replace(".", ",");
    },
  }
}
</script>

<style scoped>

.blurimg { /* non eliminare */
  filter: blur(2px);
}

.text {
  font-family: opensans;
  text-align: left;
}
.product-image {
  background-position: top center;
  background-size: cover;
  height: 500px;
  width: 100%;
}

.discounted {
  text-decoration-line: line-through;
  font-size: 15px;
  color: #7a7a7a;
}

@media screen and (max-width: 576px) {
  .product-image {
    height: 300px !important;
  }
}

@media screen and (max-width: 768px) {
  .product-image {
    height: 350px !important;
  }
}

@media screen and (max-width: 992px) and (min-width: 992px) {
  .product-image {
    height: 500px;
  }
}

</style>