<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <p class="title text-center"> Recupera password </p>
          <div class="alert alert-success" v-if="message">{{ message }}</div>
          <div class="alert alert-danger mt-3" v-if="errors.length > 0" v-for="error in errors">
            {{ error }}
          </div>
          <form @submit.prevent="checkForm()">
            <h6 class="text text-center mt-3 mb-3">
              Inserisci la tua email di registrazione. Controlla la tua casella e-mail, ti verrà inviato un messaggio con il
              link per reimpostare la tua nuova password.
            </h6>
            <div class="row d-flex justify-content-center text-center">
              <div class="col-lg-8">
                <div class="form-group mt-3">
                  <label class="text form-label">E-mail <strong>*</strong></label>
                  <input type="email" class="form-control mb-3" v-model="email">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 mt-3">
                <div class="form-group d-flex justify-content-center">
                  <button type="submit" class="primary-btn">Recupera password</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-center mb-5">
          <router-link class="link text" to="/login"> Torna al login </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "RecoverPassword",
  data() {
    return {
      email: null,
      remember: false,
      message: null,
      errors: [],
    }
  },
  methods: {
    checkForm() {
      this.errors = [];
      if (this.email) {
        this.recoverPassword();
      }
      if (!this.email) {
        this.errors.push('Email required');
      }
    },
    async recoverPassword() {
      this.$store.commit('showSpinner');
      axios.post("recover", {
        email: this.email,
        type: "website"
      }).then((response) => {
        this.$store.commit('hideSpinner');
        this.message = response.data.message;
      }).catch((error) => {
        this.$store.commit('hideSpinner');
        if (error.response.status === 400) {
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "Technical problems to recover password.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#e20036',
          });
          console.log(error);
        } else {
          console.log(error);
        }
      });
    }
  }
}
</script>

<style scoped>
strong {
  color: red;
}

.link {
  color: lightgray;
  text-decoration: underline lightgray;
}

@media (max-width: 576px) {
  .title {
    margin-top: 30px;
  }
}
</style>