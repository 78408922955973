import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm';
import router from "./router"
import store from "./store"
import App from './App.vue'
import './interceptors/axios';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import 'bootstrap/dist/css/bootstrap.min.css'

library.add(faAngleUp);

createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(store).use(router).use(
    createGtm({
        id: "GTM-NXCL52C",
        vueRouter: router,
        defer : true,
        compatibility: true,
        enabled: true,
        debug: true,
        loadScript: true,
    })
).mount('#app')
