<template>
  <div class="wrapsearch" v-if="opened">
    <div class="wraprow">
      <div class="row">
        <div class="col-2 col-lg-1 my-auto p-0">
          <svg class="icon_search" style="margin-left: 37px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
          </svg>
        </div>
        <div class="col-8 col-lg-10 pl-0 pr-0">
          <input
              class="pb-1 pt-1 w-100"
              v-model="query"
              type="text"
              placeholder="Cerca prodotto..."
              name="search"
              v-on:keyup="onInputHandler($event)"
              v-on:keyup.enter="searchProds(query)"/>
        </div>
        <div class="col-2 col-lg-1 my-auto">
          <button class="btn cancel-btn">
            <svg class="icon" v-on:click="closeSearch()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path
                  d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="boxresults">
      <p v-if="query.length === 0 && results.length === 0">
        Inserisci la parola da cercare e clicca su uno dei risultati.
        Puoi cercare per esempio "Pantalone", "T-Shirt", "Maglioncino bianco" o anche per codice prodotto, "8000",
        "100".
      </p>
      <p v-else-if="query.length !== 0 && results.length === 0">
        Nessun prodotto trovato
      </p>
      <ul v-else-if="results.length && query.length">
        <li v-bind:class="{'border' : results.length}" v-for="res in results">
          <a :href="baseurl + 'prodotto/' + res.slug">
            <div class="d-flex justify-content-left">
              <img :src="res.image" alt="Product Image" class="product-image mr-2">
              <div class="my-auto">
                {{ res.description }}
              </div>
            </div>
        </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {PATH_APP} from "@/constants/constants";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AutocompleteSearch",
  computed: {
    opened() {
      return this.$store.getters.getOpened;
    }
  },
  data() {
    return {
      query: "",
      baseurl: PATH_APP,
      results: [],
      categories: [],
    }
  },
  methods: {
    closeSearch() {
      this.$store.commit("setOpened", false);
    },
    onInputHandler(event) {
      if (this.query.length > 1) {
        this.$store.commit("setQuery", this.query);
        this.searchProds(this.query, event);
      }
    },
    async searchProds(query, event) {
      let res = await axios.post("products/search", {
        search: query,
      });
      this.results = res.data.products;
      //controlla se è stato premuto l'invio
      if (event.keyCode === 13) {
        this.$router.push({
          name: "Clothing",
          query: {search: query},
        });
        this.$store.commit("setOpened", false);
      }
    },
  },
}
</script>

<style scoped>
.product-image {
  max-width: 50px;
  display: block;
}

.wraprow {
  padding: 20px 0;
  background-color: white;
  position: relative;
}

.list-title {
  font-weight: 800;
  background-color: black;
  color: white;
  font-style: oswald;
  padding: 10px;
}

input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dedede;
}

.icon {
  width: 20px;
}

.icon_search {
  width: 22px;
}

.wrapsearch {
  margin: auto;
  max-width: 1120px;
  overflow: hidden;
  background-color: white;
}

.wrapsearch input {
  border-radius: 0;
  padding: 10px;
  font-size: 18px;
}

.border {
  border: 1px solid #dedede;
}

.boxresults {
  overflow: hidden;
  width: 100%;
  background-color: transparent;
}

.boxresults ul {
  padding: 0;
  margin: 0;
  max-height: 600px;
  overflow-y: scroll;
  background-color: #ffff;
  list-style-type: none;
  width: 100%;

}

.boxresults ul li {
  padding: 8px;
  width: 100%;
  margin: 0;
}

.boxresults ul li a {
  color: #000;
}

.cancel-btn {
  padding: 0 5px;
}

</style>