<template>
  <section>
    <div class="container success">
      <div class="row">
        <div class="col-lg-12 text-center mt-5" v-if="this.response === 'success'">
          <p class="title">Ordine inoltrato con successo!</p>
          <p>Riceverai un'email di conferma dell'ordine.</p>
          <div class="d-flex justify-content-center">
            <router-link class="primary-btn" to="/commerce">VAI ALLO SHOP</router-link>
            <template v-if="auth">
              <router-link class="primary-btn" to="/profile">VAI AI MIEI ORDINI</router-link>
            </template>
          </div>
        </div>
        <div class="col-lg-12 text-center mt-5" v-if="this.response === 'error'">
          <p class="title">Errore nel pagamento</p>
          <p>C'è stato un errore con il pagamento; riprovare più tardi.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PaymentSuccess",
  data() {
    return {
      response: "",
    }
  },
  computed : {
    auth() {
      return this.$store.getters.getAuth;
    }
  },
  methods: {
    checkResult() {
      this.response = this.$route.params.status;
    }
  },
  mounted() {
    this.checkResult();
  }
}
</script>

<style scoped>

.title {
  font-family: "Nunito Sans", sans-serif;
  font-size: 50px;
  color: black;
  margin-bottom: 20px;
}

.subtitle {
  font-family: "Nunito Sans", sans-serif;
  font-size: 25px;
  line-height: 30px;
  font-weight: 300;
}

.primary-btn {
  margin: 0 5px 0 5px;
}
</style>