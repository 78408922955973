<template>
  <section class="maintenance">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-5 mt-5">
          <h3 class="text-center">Il sito web è momentaneamente in manutenzione!</h3>
          <p>
            Ci scusiamo per il disagio ma torniamo presto online. Stiamo aggiornando il nostro catalogo.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Maintenance",
  methods: {

  }
}
</script>

<style scoped>

</style>