<template>
  <div class="topbanner" v-if="userCountry === 'Svizzera' || userCountry === 'Regno Unito (UK)'">
    I PREZZI DEI PRODOTTI INDICATI SONO AL NETTO DI IVA
  </div>
  <section class="header-wrapper" v-bind:class="{'mt-4' : userCountry === 'Svizzera' || userCountry === 'Regno Unito (UK)'}">
    <div class="header_mobile" v-if="!this.$store.state.opened">
      <HeaderMobile v-if="this.$store.state.menus.length > 0" :menus="menus" :sidebarmenu="sidebarmenu"/>
    </div>
    <div class="search_mobile" v-if="this.$store.state.opened">
      <HeaderMobile v-if="this.$store.state.menus.length > 0" :menus="menus" :sidebarmenu="sidebarmenu"/>
    </div>
    <div class="header_website">
      <HeaderWebsite v-if="this.$store.state.menus.length > 0" :menus="menus"/>
    </div>
  </section>
</template>

<script>
import HeaderWebsite from "@/components/HeaderWebsite";
import HeaderMobile from "@/components/HeaderMobile";

export default {
  name: "Header",
  props: ['menus', 'sidebarmenu'],
  components: {
    HeaderMobile,
    HeaderWebsite
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
    userCountry(){
      return this.$store.getters.getUserCountry;
    }
  },
  data() {
    return {
      opened: false
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  }
}
</script>


<style scoped>
.search_mobile {
  height: 1000px;
  z-index: 9999;
}

.header-wrapper {
  position: fixed;
  padding: 5px 10px 5px 10px;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  background-color: #fff;
  z-index: 9999 !important;
}

.header_mobile {
  width: 100%;
  height: 60px;
  overflow: hidden;
  z-index: 9999;
}

.topbanner{
  background-color: #e0a800;
  font-size: 12px;
  position: fixed;
  color: #fff;
  height: 1.5rem;
  width: 100%;
  text-align: center;
  z-index: 100000;
  padding: 3px;
}

/* web */
@media (min-width: 768px) {
  .header_mobile {
    visibility: hidden;
    display: none;
  }
}

/* mobile */
@media (max-width: 767px) {
  .header_website {
    visibility: hidden;
    display: none;
  }
  .header-wrapper{
    border-bottom: 1px solid #dedede;
  }
}

</style>