<template>
  <section class="ordine">
    <div class="container">
      <p class="title text-center mt-3">Riepilogo ordine</p>
      <div class="row">
        <div class="col-lg-12 topcard mt-3 mb-3">
          <p class="text-left text-bigger mt-3 mb-3"> <span style="font-family: oswald">NUMERO D'ORDINE: </span> {{ order.idorder }} </p>
          <div class="table-responsive">
            <table>
              <thead>
              <tr class="text-center">
                <th></th>
                <th class="text-bigger">PRODOTTO</th>
                <th class="text-bigger">TAGLIA</th>
                <th class="text-bigger">QUANTITÀ</th>
                <!--<th class="toptext">PREZZO x1</th>-->
              </tr>
              </thead>
              <tbody>
              <tr class="text-center" v-for="detail in details">
                <td class="img-width">
                  <img v-if="detail.image" :src="detail.image" alt="">
                  <img v-else :src="base + 'img/placeholder.png'" alt=""/>
                </td>
                <td class="text-center" style="width: 30%">
                  <p class="text">{{ detail.productname }}</p>
                </td>
                <td class="text-center" style="width: 10%">
                  <p v-if="detail.variantname === null" class="text"> - </p>
                  <p v-else class="text"> {{ detail.variantname ? detail.variantname.split("-")[2] : "-" }} </p>
                </td>
                <td style="width: 10%">
                  <p class="text text-center">{{ detail.qnt }} </p>
                </td>
                <!--<td>
                  {{ detail.unitprice }} €
                </td>-->
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12 text-center">
          <router-link to="/shop" class="primary-btn mt-3">VAI ALLO SHOP</router-link>
        </div>
        <div class="col-lg-6 col-12 text-center">
          <router-link to="/profile" class="primary-btn mt-3 mb-4">TORNA AL TUO PROFILO</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import {useRoute} from "vue-router/dist/vue-router";
import {PATH_APP} from "@/constants/constants";

export default {
  name: "RiepilogoOrdine",
  data() {
    return {
      idorder: String,
      order: Object,
      details: [],
      base: PATH_APP
    }
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    async getOrder() {
      this.$store.commit('showSpinner');
      const route = useRoute();
      this.idorder = route.params.idordine;
      let token = localStorage.getItem("token");
      await axios.get("orders/" + this.idorder, {
        headers: {Authorization: `Bearer ${token}`}
      }).then((resp) => {
        this.order = resp.data.order;
        this.details = resp.data.order.details;
        this.coupon = resp.data.coupon;
        console.log("order", this.order);
      }).catch((error) => {
        console.log(error);
      });
      this.$store.commit('hideSpinner');
    },
    formatDate(value) {
      return value.substr(0, 10);
    }
  },

}
</script>

<style scoped>
p {
  margin: 0;
}

table thead tr th {
  font-size: 25px;
  font-weight: normal;
  font-family: oswald;
}
.text {
  font-size: 23px;
}

.img-width {
  width: 10%;
}
.img-width img {
  width: 70%;
}

.topcard {
  border-top: 1px solid #9f9578;
}

table {
  width: 100%;
}

td {
  width: 33%;
}

.primary-btn {
  width: 50%;
}

@media (max-width: 576px) {
  .img-width {
    width: 15%;
  }

  .img-width img {
    width: 100%;
  }

  .primary-btn {
    width: 80%;
  }

  table thead tr th {
    font-size: 18px;
    font-weight: normal;
    font-family: oswald;
  }

  .text {
    font-size: 13px;
  }

}

</style>