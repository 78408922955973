<template>
  <div :class="[data.columnb, data.classes]">
      <swiper :loop="true" :navigation="true" :modules="modules" :pagination="{clickable: true}"
              :breakpoints="{
            0: {
              slidesPerView: 1,
              spaceBetween: 15
            },
            569: {
              slidesPerView: 2,
              spaceBetween: 15
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 15
            }
          }"
              class="mySwiper marginsection">
        <swiper-slide v-for="(item, index) in data" :key="index">
          <img :src="item.url" :alt="item.alt">
        </swiper-slide>
      </swiper>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation} from 'swiper';

export default {
  props: ['data'],
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
};
</script>

<style scoped>
/* Add custom styles if needed */
</style>
