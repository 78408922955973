<template>
  <section>
    <div class="container">
      <p class="title text-center">Spedizione e resi</p>
      <span class="text-italic text-center">Il nostro Servizio Online con operatore disponibile a rispondere ai messaggi è attivo dal lunedì al venerdì, dalle 9.00 alle 17.00.</span>
      <div class="row">
        <div class="col-lg-12">
          <p class="text-italic mt-3">DOMANDE PIÙ FREQUENTI</p>
          <h6 class="domanda">COME POSSO SAPERE LA TAGLIA PIÙ GIUSTA PER ME? </h6>
          <p class="text text-left">
            Per la taglia più giusta per te, ci sono delle modelliste che possono indirizzarti alla taglia più adatta
            alla tua fisicità.
            <br>
            Basta scrivere un messaggio su TELEGRAM (scarica questa App sul tuo cellulare).
            Ci trovi come nome —> La Mora Glamour, oppure tramite numero di telefono, digitando sempre sulla App questo
            numero +39 392 23 86 435.
            <br>
            Oppure puoi contattarci tramite messaggio su WHATSAPP al +39 392 23 86 435.
            <br>
            Nel messaggio scrivici sempre le tue misure di:
            <br>
            – ALTEZZA
            <br>
            – CIRCONFERENZA SENO
            <br>
            – MISURA VITA (nel punto più stretto)
            <br>
            – MISURA DOVE ABBOTTONI (altezza ombelico)
            <br>
            – CIRCONFERENZA SEDERE (mi raccomando la parte più sporgente)
            <br><br>
            Mandaci, insieme al messaggio, la foto dell’articolo a cui sei interessata. Noi così, in base al tuo fisico e
            alle tue proporzioni, riusciamo a scegliere la taglia più giusta e che ti valorizzi maggiorante. Possiamo anche
            crearti dei look! Non solo consigliarti la taglia.
            <br>
            Siamo noi poi, insieme a te, nei messaggi che cercheremo di capire anche la tua PERSONALITA’ per darti
            sempre
            l’articolo più giusto per te.</p>
          <h6 class="domanda">QUALE METODO DI PAGAMENTO ACCETTATE? </h6>
          <p class="text text-left"> Puoi pagare con tutte le principali CARTE DI CREDITO. L’importo verrà addebitato sulla carta al momento
            della conferma dell’ordine. Puoi salvare i dati della carta di credito per non inserirli durante i prossimi
            acquisti.
            <br>
            Utilizziamo STRIPE, che è un sistema simile a PayPal. E’ un sistema di pagamento innovativo che permette di
            rendere le transazioni di denaro online più sicure e facili da effettuare. Sviluppato per pagare in tutta
            sicurezza con elevati sistemi anti-frode.
            <br>
            (Creare un profilo su STRIPE è molto semplice: è necessario inserire i propri dati e procedere. Una volta
            entrati nel sistema è possibile vedere sulla sinistra tutte le funzionalità.)
            Per maggiori dettagli: stripe.com <br><br>
            Puoi pagare con BONIFICO BANCARIO. Se decidi di usare questo metodo di pagamento ricordati di fare il pagamento entro 2 giorni da quando hai
            effettuato l'ordine; il tuo pacco verrà poi spedito appena ci verrà accreditato l'importo. <br>
            Qui di seguito trovi i dati:
            <br><strong>URANUS SRL</strong>
            <br><strong>IT76 Z 05034 59590 000000002937</strong>
            <br><strong>BANCO BPM Filiale di Mozzecane</strong>
            <br> <br>
            Ora puoi pagare anche con CONTRASSEGNO! Questo metodo di pagamento ha un costo fisso di €5 e ti permette di saldare direttamente al corriere
            l'importo del tuo ordine.<br> N.B. Preparati in anticipo la somma esatta per il corriere per evitare perdite di tempo
            dato che non hanno il resto.
          </p>
          <h6 class="domanda"> POSSO CAMBIARE LA TAGLIA O IL COLORE? </h6>
          <p class="text text-left">Se un articolo non ti dovesse piacere, puoi cambiare la taglia oppure il colore.
            <br>
            Se invece non trovi niente che ti possa piacere per il cambio, possiamo anche restituirti i soldi, per
            l’intero valore del reso, al netto dei costi di spedizione.
            <br>
            Puoi anche decidere di richiedere UN BUONO da utilizzare sul prossimo acquisto. IL BUONO NON HA SCADENZA e dovrà essere speso
            interamente in un’unica soluzione. </p>

          <h6 class="domanda"> POSSO RENDERE IL MIO ORDINE?</h6>
          <p class="text text-left"> Puoi richiedere il reso, entro 15 giorni dal ricevimento della merce. Invia la tua richiesta tramite
            mail
            a resi@lamoraglamour.com specificando:
            <br>
            Il numero dell’ordine.
            <br>
            Il codice dell’articolo che vuoi restituire, taglia e colore.
            <br>
            Motivo del reso.
            <br>
            Se vuoi cambiare taglia o colore.
            <br>
            Se vuoi un buono in sostituzione.
            <br>
            Se vuoi il riaccredito dell’importo del capo/dei capi che rendi. In questo caso, scrivici l’Iban, la banca
            d’appoggio ed il nome e cognome dell’intestatario del conto corrente.
            <br>
            I capi devono essere restituiti integri e provvisti di tutti i cartellini nella loro confezione originale,
            imballati per la spedizione in modo sicuro, quindi consegnati allo spedizioniere che preferisci.</p>

          <h6 class="domanda"> IL RESO E’ GRATUITO? </h6>
          <p class="text text-left">
            Le spese e l’organizzazione del reso sono a carico tuo, con un corriere che preferisci.
            <br>
            Nel caso in cui, siamo stati noi a consigliarti la taglia non corretta con le misure che ci hai fornito, in
            questo caso, il reso e la spedizione successiva sarà a carico nostro.
            <br>
            ATTENZIONE: Se hai sbagliato a fornirci le tue misure, non rispondiamo del reso gratuito, ma dovrai inviare
            il
            pacco di reso, a tue spese. E anche la spedizione successiva, sarà a carico tuo.
          </p>

          <h6 class="domanda"> A QUANTO AMMONTANO LE SPESE DI SPEDIZIONE?</h6>
          <p class="text text-left">
            Quando effettui un acquisto, nella conferma d’ordine, trovi le spese di spedizione.
            <br>
            Per tutta l’Italia il costo di spedizione ammonta a €9. Dalle €200 in su, la spedizione è gratuita.
            <br>
            Per l’estero, nella conferma d’ordine, compaiono le spese per ogni singolo paese.
            <br>
            ATTENZIONE: Per i paesi fuori dall’Unione Europea la spedizione, le spese doganali e le spese o tasse per il
            riaccredito per eventuale reso sono tutte a carico del cliente.
            <br>
            Per le spedizioni fuori Italia, non vale la regola del “RESO GRATUITO” se sbagliamo la taglia. (cerchiamo di
            avere ancora più attenzione a consigliarti la taglia, con messaggi ancora più intensificati con te). Per le
            spedizioni fuori Italia, il reso e’ a carico del cliente.
          </p>
          <h6 class="domanda"> COME FUNZIONA IL RIMBORSO? </h6>
          <p class="text text-left">
            Provvederemo a rimborsare l’intero valore del reso il prima possibile e comunque entro 15 giorni dal
            ricevimento del reso, al netto dei costi di spedizione.
            <br>
            L’importo verrà accreditato sullo stesso metodo di pagamento utilizzato per l’acquisto. <br>
            N.B. Se hai scelto il CONTRASSEGNO come pagamento, l'accredito del reso ti verrà accreditato con bonifico
            inviando i tuoi dati bancari alla mail resi@lamoraglamour.com
          </p>
          <h6 class="domanda">SPEDIZIONI: </h6>
          <p class="text text-left">
            Le spedizioni sono attive dal lunedì al venerdì. Tutti gli articoli che trovi sul sito sono già disponibili
            a
            magazzino e pronti per essere spediti.
            <br>
            In generale il pacco arriva dopo 1/3 gg lavorativi, dipende anche dal tipo di pagamento effettuato.
            <br>
            Se utilizzato il metodo BONIFICO, bisognerà attendere prima l’accredito della somma, e poi il pacco potrà
            partire.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ShippingAndReturns",
  mounted() {
    window.scrollTo(0,0);
  }
}
</script>

<style scoped>
.domanda {
  font-size: 20px;
  margin: 15px 0;
  font-weight: 800;
  font-family: oswald;
}
@media (max-width: 576px) {
  .title {
    margin-top: 30px;
  }
}
</style>