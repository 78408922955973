<template>
  <div class="row mt-5 justify-content-center" :class="[data.columnb, data.classes]">
    <div v-for="(item, index) in data" :key="index" class="col-sm-6 col-md-3 text-center">
      <img :src="item.url" :alt="item.alt" class="images">
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {};
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 200px;
}
.images{
  width: 100%;
}

/* Add custom styles if needed */
</style>
