<template>
  <div class="wr-mobile">
    <div class="navbar">
      <!-- se la ricerca NON è aperta -->
      <template v-if="!opened">
        <!-- menu hamburger -->
        <button class="navbar-toggler" type="button" v-on:click="toggleSidebar">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path stroke="black" stroke-width="3" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/>
          </svg>
        </button>
        <!-- logo -->
        <router-link to="/" class="navbar-brand navbar-brand-centered">
          <img src="/img/la-mora-logo.png" class="logo" alt="">
        </router-link>
        <!-- cerca e carrello -->
        <div class="">
          <ul class="mobilemenu">
            <li class="nav-item">
              <a href="javascript:void(0);" v-on:click="openSearch()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon_mobilehome mt-1">
                  <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
                </svg>
              </a>
            </li>
            <li class="nav-item">
              <router-link to="/cart">
                <span class="badge badge-pill badge-dark badge-black" style="float:right;" v-if="cartProds">{{ cartProds }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="icon_mobilehome">
                  <path d="M160 112c0-35.3 28.7-64 64-64s64 28.7 64 64v48H160V112zm-48 48H48c-26.5 0-48 21.5-48 48V416c0 53 43 96 96 96H352c53 0 96-43 96-96V208c0-26.5-21.5-48-48-48H336V112C336 50.1 285.9 0 224 0S112 50.1 112 112v48zm24 48a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm152 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"/>
                </svg>
              </router-link>
            </li>
          </ul>
        </div>
      </template>
      <!-- se la ricerca è aperta -->
      <template v-if="opened">
        <Search class="search"/>
      </template>
    </div>
  </div>
  <div>
    <Sidebar v-if="openedSidebar" style="z-index: 111 !important;" id="side-trans" class="sidebar" :sidebarmenu="sidebarmenu"/>
  </div>
</template>

<script>

import Sidebar from "@/components/Sidebar";
import Search from "@/components/Search";

export default {
  name: "HeaderMobile",
  props: ['sidebarmenu'],
  components: {Sidebar, Search},
  data() {
    return {
      //idCat: '',
      //startF: 0,
    }
  },
  computed: {
    categories() {
      return this.$store.getters.getCategories;
    },
    /*totalCount() {
      return this.$store.getters.getTotalCount;
    },*/
    cartProds() {
      return this.$store.getters.getCartTotalProducts;
    },
    openedSidebar(){
      return this.$store.getters.getStatusSidebar;
    },
    opened(){
      return this.$store.getters.getOpened;
    }
    /*filter() {
      return this.$store.getters.getFilter;
    }*/
  },
  methods: {
    openSearch() {
      if (this.openedSidebar) {
        this.toggleSidebar();
      }
      this.$store.commit("setOpened", true);
    },
    toggleSidebar(){
      if(this.openedSidebar){
        /*
        let sbtr = document.getElementById("side-trans");
        sbtr.classList.add("sb-up");
        sbtr.addEventListener('animationend', () => {
          this.$store.commit("setOpenedSidebar", false);
        });
         */
        this.$store.commit("setOpenedSidebar", false);
      }else{
        this.$store.commit("setOpenedSidebar", true);
      }
    },
    slugify(text) {
      return text.toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '-')
          .replace(/^-+|-+$/g, '');
    },
  },
  mounted() {
    this.$store.dispatch("getCategories");
  }
}

</script>

<style scoped>
.badge{
  border-radius: 50%;
  width: 20px;
  padding: 4px 1px;
  margin-left: 2px;
  font-size: 12px;
}
.navbar-toggler,.navbar-toggler:focus, .navbar-toggler:active{
  border:none !important;
  outline: none;
  box-shadow: none;
}
.search {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}

.wr-mobile {
  width: 100%;
  background-color: white;
  overflow: hidden;
}

.logo {
  width: 150px;
}

.icon_mobilehome {
  width: 22px;
}

.mobilemenu{
  list-style-type: none;
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
}

.mobilemenu li{
  display: inline-block;
}

.mobilemenu li a{
  display: block;
}

.navbar-brand-centered{
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-item{
  margin-left: 10px;
}

.badge-black{
  background-color: #000;
}

</style>