<template>
  <div class="container">
    <p class="title text-center title-p"> BLOG </p>
    <!--<ul class="text-center tag" style=" ">
      <li v-for="tag in tags">
        <router-link :to="'/tag/' + tag.slug + '/'">  {{ formatName(tag.name) }} </router-link>
      </li>
    </ul>-->
    <div class="desktop-tag">
      <div class="row justify-content-around border-bottom border-top p-2" style=" ">
        <div class="col-2 text-center" v-bind:class="{'first' : index===0}" v-for="(tag, index) in tags">
          <router-link class="tag" v-if="index <= 5" :to="'/tag/' + tag.slug + '/'"> {{
              formatName(tag.name)
            }}
          </router-link>
        </div>
      </div>
      <div class="row justify-content-around border-bottom p-2" style=" ">
        <div class="col-2 text-center" v-bind:class="{'first' : index===6}" v-for="(tag, index) in tags">
          <router-link class="tag" v-if="index >= 6 && index <= 11" :to="'/tag/' + tag.slug + '/'">
            {{ formatName(tag.name) }}
          </router-link>
        </div>
      </div>
      <div class="row justify-content-around border-bottom p-2" style=" ">
        <div class="col-2 text-center" v-bind:class="{'first' : index===12}" v-for="(tag, index) in tags">
          <router-link class="tag" v-if="index >= 12 && index <= 17" :to="'/tag/' + tag.slug + '/'">
            {{ formatName(tag.name) }}
          </router-link>
        </div>
      </div>
      <div class="row justify-content-around border-bottom p-2" style=" ">
        <div class="col-4 text-center" v-bind:class="{'first' : index===18}" v-for="(tag, index) in tags">
          <router-link class="tag" v-if="index >= 18 && index <= 21" :to="'/tag/' + tag.slug + '/'">
            {{ formatName(tag.name) }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="mobile-tag">
      <div class="row justify-content-around border-bottom border-top p-2" style=" ">
        <div class="col-4 text-center my-auto" v-for="(tag, index) in tags">
          <router-link class="tag" v-if="index <= 2" :to="'/tag/' + tag.slug + '/'"> {{
              formatName(tag.name)
            }}
          </router-link>
        </div>
      </div>
      <div class="row justify-content-around border-bottom p-2" style=" ">
        <div class="col-4 text-center my-auto" v-for="(tag, index) in tags">
          <router-link class="tag" v-if="index >= 3 && index <= 5" :to="'/tag/' + tag.slug + '/'">
            {{ formatName(tag.name) }}
          </router-link>
        </div>
      </div>
      <div class="row justify-content-around border-bottom p-2" style=" ">
        <div class="col-4 text-center my-auto" v-for="(tag, index) in tags">
          <router-link class="tag" v-if="index >= 6 && index <= 8" :to="'/tag/' + tag.slug + '/'">
            {{ formatName(tag.name) }}
          </router-link>
        </div>
      </div>
      <div class="row justify-content-around border-bottom p-2" style=" ">
        <div class="col-4 text-center my-auto" v-for="(tag, index) in tags">
          <router-link class="tag" v-if="index >= 9 && index <= 11" :to="'/tag/' + tag.slug + '/'">
            {{ formatName(tag.name) }}
          </router-link>
        </div>
      </div>
      <div class="row justify-content-around border-bottom p-2" style=" ">
        <div class="col-4 text-center my-auto" v-for="(tag, index) in tags">
          <router-link class="tag" v-if="index >= 12 && index <= 14" :to="'/tag/' + tag.slug + '/'">
            {{ formatName(tag.name) }}
          </router-link>
        </div>
      </div>
      <div class="row justify-content-around border-bottom p-2" style=" ">
        <div class="col-4 text-center my-auto" v-for="(tag, index) in tags">
          <router-link class="tag" v-if="index >= 15 && index <= 17" :to="'/tag/' + tag.slug + '/'">
            {{ formatName(tag.name) }}
          </router-link>
        </div>
      </div>
      <div class="row justify-content-around border-bottom p-2" style=" ">
        <div class="col-4 text-center my-auto" v-for="(tag, index) in tags">
          <router-link class="tag" v-if="index >= 18 && index <= 20" :to="'/tag/' + tag.slug + '/'">
            {{ formatName(tag.name) }}
          </router-link>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-lg-4 col-6 mt-3 mb-3 maintag-container" v-for="cat in categories">
        <router-link :to="'/category/' + cat.slug + '/'">
          <img v-if="cat.image" :src="cat.image" class="card-img-top" alt="...">
          <img v-else src="/img/placeholder.png" class="card-img-top" alt="...">
          <p class="maintag">{{ formatName(cat.name) }}</p>
        </router-link>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-lg-12">
        <p class="title mt-5"> LE "ULTIME" DALLA MORA </p>
        <div class="col-lg-5 mx-auto">
          <div class="card-post">
            <router-link :to="'/post/' + lastPost.id + '/'">
              <p class="post_title"> {{ lastPostTitle }}</p>
              <div class="d-flex justify-content-center">
                <img :src="lastPostImage" class="lastPostImg" alt="">
              </div>
              <!-- <p class="post_exp" v-html="lastPostExcerpt"></p>
               <p class="post_tags" v-for="tag in lastPostTags"> # {{ formatName(tag.name) }}</p>
               <div class="d-flex justify-content-center mb-5">
                 <img :src="lastPostImage" class="lastPostImg" alt="">
               </div>-->
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {WP_BLOG} from "@/constants/constants";
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Blog",
  data() {
    return {
      tags: [],
      categories: [],
      lastPost: [],
      lastPostTitle: null,
      lastPostTags: [],
      lastPostImage: null,
      lastPostExcerpt: null,
    }
  },
  mounted() {
    this.getTags();
    this.getCategories();
    this.getLastPost();
  },
  methods: {
    async getTags() {
      this.$store.commit("showSpinner");
      await axios
          .get(WP_BLOG + "tags", {
            params: {
              "per_page": 100,
            }
          })
          .then(response => {
            this.tags = response.data;
          })
    },
    async getCategories() {
      await axios
          .get(WP_BLOG + "categories")
          .then(response => {
            this.categories = response.data;
          })
    },
    async getLastPost() {
      await axios
          .get(WP_BLOG + "posts", {
            params: {
              "orderby": "date",
            }
          })
          .then(response => {
            this.lastPost = response.data[0];
            this.lastPostTitle = this.lastPost.title.rendered;
            this.lastPostExcerpt = this.lastPost.excerpt.rendered;
            let idTags = this.lastPost.tags;
            this.getTagsByID(idTags);
            let idPost = this.lastPost.id;
            this.getImage(idPost);
            console.log("LAST", this.lastPost);
          })
      this.$store.commit("hideSpinner");
    },
    async getTagsByID(id) {
      await axios
          .get(WP_BLOG + "tags", {
            params: {
              "include": id,
            }
          })
          .then(response => {
            this.lastPostTags = response.data;
          })
    },
    async getImage(idLastPost) {
      await axios
          .get(WP_BLOG + "media", {
            params: {
              "id": idLastPost,
            }
          })
          .then(response => {
            this.lastPostImage = response.data[0].source_url;
          })
    },
    formatName(value) {
      return String(value).replace("&amp;", "&");
    },
  }
}
</script>

<style scoped>
.card-post {
  box-shadow: 5px 5px 10px #f6f6f6,
  -5px -5px 10px #f6f6f6,
  5px -5px 10px #f6f6f6,
  -5px 5px 10px #f6f6f6;
  padding: 10px 0;
}

.border-bottom, .border-top {
  border-color: #ababab !important;
}

.row .col-2, .row .col-4 {
  border-right: 1px solid #ababab;
}

.first {
  border-left: 1px solid #ababab;
}

.tag {
  color: #ababab;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-family: opensans;
}

.tag li:nth-last-child(n+8) {
  border-bottom: 1px solid #ababab;
}

.post_title {
  font-size: 25px;
  font-family: oswald-light;
}

.post_tags {
  color: grey;
}

.lastPostImg {
  width: 50%;
}

.card-img-top {
  opacity: 0.5;
}

.desktop-tag {
  display: block;
  visibility: visible;
}

.mobile-tag {
  display: none;
  visibility: hidden;
}

.maintag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 40px;
  font-family: oswald;

}

.maintag-container {
  position: relative;
  padding: 30px;
}

@media (min-width: 576px) and (max-width: 1200px) {


}


@media (max-width: 576px) {

  .mobile-tag {
    display: block;
    visibility: visible;
  }

  .desktop-tag {
    display: none;
    visibility: hidden;
  }

  .title-p {
    padding-top: 30px !important;
  }

  .tag {
    font-size: 13px;
  }

  .maintag-container {
    padding: 10px;
  }

  .maintag {
    font-size: 18px;
  }
}
</style>