<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="title mt-5 mb-3 text-center font-weight-bold">COME ACQUISTARE DA NOI</h2>
          <p class="subtitle text-center">Abbiamo un SERVIZIO DI CONSULENZA che ti aiuta a scegliere la taglia più
            giusta per te. <br>
            Conosciamo esattamente la vestibilità dei capi che produciamo e siamo in grado di consigliarti al meglio,
            per valorizzare la tua figura.</p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-6 my-auto">
          <p class="pg">COME SAPERE LA TAGLIA PIÙ GIUSTA?</p>
          <p class="text text-center">
            Innanzitutto abbiamo bisogno di sapere le tue misure.
          </p>
          <p class="text text-center">
            Utilizza un metro da sarta e prendi le seguenti misure:
          </p>
          <ol>
            <li>ALTEZZA</li>
            <li>CIRCONFERENZA SENO</li>
            <li>CIRCONFERENZA VITA NEL PUNTO PIÙ STRETTO</li>
            <li>CIRCONFERENZA VITA DOVE ABBOTTONI</li>
            <li>CIRCONFERENZA BACINO LIVELLO GLUTEI</li>
          </ol>
          <br>
          <p class="text text-center mb-5">
            Con queste misure riusciamo a capire la tipologia di fisico e le tue proporzioni. <br> <br>
            Per sapere quale taglia fa per te per ogni articolo, ci sono quattro modelliste che possono indirizzarti
            alla taglia più adatta alla tua fisicità.
          </p>
        </div>
        <div class="col-lg-6">
          <div class="contiframe">
            <iframe class="iframe" width="100%" height="380" src="https://www.youtube.com/embed/gTWIiN2Iwuc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <!-- andrà sostituito con il video -->
          <!--<img class="videowidth" src="/img/comeacquistare/videomisure.jpg" alt="">-->
        </div>
        <div class="col-lg-12 mt-5">
          <p>
            Puoi scrivere un messaggio su Telegram (scarica questa App sul tuo cellulare, ci trovi come LA MORA
            GLAMOUR) oppure tramite
            numero di telefono, digitando questo numero +39 392 2386435. Puoi anche contattarci tramite messaggio
            WhatsApp, sempre allo stesso numero.
            Nel messaggio scrivici sempre le tue misure. <br> <br>
            Mandaci anche la foto dell'articolo a cui sei interessata.
            Possiamo anche creare assieme dei look che ti rappresentano in base alla tue personalità! <br> <br>
            Puoi utilizzare anche la nostra App LA MORA GLAMOUR che ti suggerirà qual è la taglia giusta per il
            prodotto selezionato, in base alle tue misure.
            E se non la trovi sei sempre in contatto con noi! <br> <br>
            Se invece procedi direttamente all'ordine, senza passare dalle modelliste, ricordati di scrivere sempre nelle note le tue misure.
            Ogni ordine viene controllato dalle modelliste che, se riterranno di proporti un cambio taglia oppure di
            sconsigliarti un articolo perchè non ti valorizza, ti
            contatteranno via messaggio al numero di telefono di riferimento.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <p class="subtitle mt-4">COME FUNZIONA IL RESO DA NOI?</p>
          <p class="text mb-5">
            Hai 15 giorni dal ricevimento dell'ordine per valutare il reso, scrivendo una mail a
            resi@lamoraglamour.com
          </p>
          <ol>
            <li>
              Se sbagliamo noi a consigliarti la taglia in base alle misure che ci hai fornito (può succedere) il reso
              sarà a nostro carico.
            </li>
            <li>
              Se invece vuoi rendere dei capi per svariati motivi (non ti piace come vestono, ci hai fornito misure non
              corrette, hai cambiato idea, ecc) il reso sarà a tuo carico.
            </li>
          </ol>
          <p class="text mt-4 mb-4">
            In caso di reso, puoi scegliere:
          </p>
          <ol>
            <li>
              Il riaccredito dell'importo speso. Provvederemo a rimborsare il valore del reso entro 15 giorni dal
              ricevimento del tuo pacco, al netto dei costi di spedizione, con lo stesso metodo di pagamento che hai
              utilizzato per l'acquisto.
            </li>
            <li>
              Un buono che non ha scadenza pari all'importo speso, da utilizzare per acquisti futuri online oppure
              presso il nostro show-room.
            </li>
          </ol>
          <p class="text mt-4 mb-5">
            <i>
              Nel pacco troverai sempre un'informativa dettagliata con indicata la procedura da seguire in caso di
              reso.
            </i>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ComeAcquistare",
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>