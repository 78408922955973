<template>
    <div class="container">
      <p class="title title-p text-center mb-0"> PREFERITI </p>
      <div class="row">
        <div class="col-lg-12">
          <div class="shopping__cart__table" v-if="favProducts.length > 0">
            <table>
              <tbody>
              <tr v-for="prod in favProducts">
                <td class="img-width">
                  <router-link :to="'/prodotto/' + prod.id">
                    <img v-if="prod.images[0]" :src="prod.images[0]?.imageUrl" alt="">
                    <img v-else :src="base + 'img/placeholder.png'" alt=""/>
                  </router-link>
                </td>
                <td class="text-center" style="width: 50%; padding: 0 20px;">
                  <router-link :to="'/prodotto/' + prod.id">
                    <p class="text-bigger">{{ prod.description }}</p> <br>
                  </router-link>
                </td>
                <td style="width: 8%">
                  <a class="close_button justify-content-center d-flex"
                     v-on:click="removeFromFavs(prod.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                      <path
                          d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                    </svg>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="row mt-4">
              <div class="col-lg-12 col-12 mb-3 text-center">
                <router-link class="primary-btn" to="/commerce">Torna allo shop</router-link>
              </div>
              <div class="col-lg-12 col-12 text-center emptyfav">
                <a v-on:click="emptyFavs">Svuota preferiti</a>
              </div>
            </div>
          </div>
          <div v-else class="no_prod">
            <p class="text-bigger text-center mb-3">
              Non ci sono prodotti nei preferiti.
            </p>
            <div class="d-flex justify-content-center">
              <router-link class="primary-btn" to="/commerce">
                Torna allo shop
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import {PATH_APP} from "@/constants/constants";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Favourites",
  data() {
    return {
      idsProds: [],
      base: PATH_APP,
      favProducts : []
    }
  },
  mounted() {
    let favs = JSON.parse(localStorage.getItem("favsLaMora"));
    if (favs && favs !== "" && favs.length) {
      this.idsProds = favs;
      this.getFavsProducts();
    }
  },
  methods: {
    async getFavsProducts() {
      this.$store.commit("showSpinner");
      //this.$store.dispatch("emptyFavProducts");
      await axios.post("products", {
        "start": 0,
        "limit": 100,
        "ids": this.idsProds,
        "type": "website"
      }).then(response => {
        this.favProducts = response.data.products;
        this.$store.commit("setTotalFavProds", response.data.totalCount);
        this.$store.commit("hideSpinner");
      });
    },
    emptyFavs() {
      localStorage.removeItem("favsLaMora");
      this.$store.commit("setTotalFavProds",0);
      this.favProducts = [];
      this.idsProds = [];
    },
    removeFromFavs(idprod) {
      let favs = JSON.parse(localStorage.getItem("favsLaMora"));
      const index = favs.indexOf(idprod);
      favs.splice(index, 1);
      this.idsProds = favs;
      localStorage.setItem("favsLaMora", JSON.stringify(favs));
      if(favs.length) {
        this.favProducts = this.favProducts.filter(function (x) {
          return x !== idprod
        });
        this.getFavsProducts();
      }else{
        this.favProducts = [];
      }
      this.$store.commit("setTotalFavProds", favs.length);
    }
  },
}
</script>

<style scoped>
.img-width {
  width: 8%;
}

h2 {
  font-size: 40px;
  font-weight: 500;
}

.shopping__cart__table table tbody tr td {
  padding-bottom: 10px;
  padding-top: 10px;
}

svg {
  width: 30px;
  margin-bottom: 20px;
}

.close_button {
  cursor: pointer;
}

.shopping__cart__table table thead tr th, .cart__discount h6, .cart__total h6 {
  color: black;
  font-weight: 700;
  font-size: 19px;
  text-transform: uppercase;
}

.no_prod {
  font-size: 23px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
}

.emptyfav {
  cursor: pointer;
  font-size: 15px;
  color: #c4c4c4;
  font-family: opensans-light;
}

.emptyfav a {
  color: #c4c4c4;
  text-decoration-line: underline;
  -webkit-text-decoration-line: underline;
  text-decoration-color: #c4c4c4;
  -webkit-text-decoration-color: #c4c4c4;
}

.text-bigger {
  margin-bottom: 0;
}

.close_button {
  cursor: pointer;
}

.cart__total ul li {
  font-size: 17px;
  font-weight: 300;
}

.cart__total ul li span {
  color: #000;
  font-size: 20px;
}

.imgname h6 {
  margin: auto;
}

/* mobile */
@media only screen and (max-width: 767px) {
  .title-p {
    padding-top: 30px !important;
  }

  .img-width {
    width: 20%;
  }

  .text-bigger {
    font-size: 16px;
    margin-bottom: 0;
  }

  .shopping__cart__table table thead tr th {
    font-size: 17px;
  }

  .imgname h6 {
    font-size: 14px;
  }

  .cart__total h6, .cart__discount h6 {
    font-size: 17px;
  }

  svg {
    width: 20px;
    margin-bottom: 20px;
  }
}
</style>