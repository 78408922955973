<template>
  <!--carica gli hook o il contenuto in base alla pagina -->
  <template v-for="page in filteredPageData" :key="page.id">
    <template v-if="page.content != null">
      <div class="container pageview">
        <div class="row">
          <!--IDEA va qui se è il contenuto è stato creato tramite editor-->
          <div v-html="page.content">
          </div>
        </div>
      </div>
    </template>
    <!--IDEA: va qui se il contenuto è stato creato componendo gli hooks  -->
    <div class="container pageview" v-else>
      <div class="row">
        <template v-for="component in page.components" :key="component.id">
          <template v-if="component.type === 'slider'">
            <Slider :data="component.files"/>
          </template>
          <template v-else-if="component.type === 'carousel'">
            <Carousel :data="component.files"/>
          </template>
          <template v-else-if="component.type === 'text'">
            <CmsText :data="component"/>
          </template>
          <template v-else-if="component.type === 'image'">
            <CmsImage :data="component"/>
          </template>
          <template v-else-if="component.type === 'accordion'">
            <CmsAccordion :data="component"/>
          </template>
        </template>
      </div>
    </div>
  </template>
</template>

<script>
import Slider from "@/components/CmsSlider.vue";
import Carousel from "@/components/CmsCarousel.vue";
import CmsAccordion from "@/components/CmsAccordion.vue";
import CmsImage from "@/components/CmsImage.vue";
import CmsText from "@/components/CmsText.vue";

export default {
  props: ['pageData', 'pageTitle', 'imageUrl'],
  watch: {
    pageTitle(newTitle) {
      //per impostare il titolo della pagina in base a quella che visitiamo (viene passato da App)
      document.title = newTitle;
    },
  },
  components: {
    CmsText,
    CmsImage,
    CmsAccordion,
    Slider,
    Carousel
    // Aggiungi altri componenti se necessario
  },
  computed: {
    filteredPageData() {
      const filteredData = this.pageData.find(page => page.slug === this.$route.path);
      return filteredData ? [filteredData] : [];
    },
  },
};
</script>

<style>
</style>
