<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        <p class="text-bigger title-p mb-0">{{ product?.category?.description }}</p>
        <p class="title">{{ product?.description }}</p>
        <SliderImages v-if="images?.length !== 0" :images="images" :pathimgvideo="product?.pathimgvideo" :pathvideo="product?.pathvideo"/>
        <img v-else class="image" :src="base + 'img/placeholder.png'" alt=""/>
        <!-- prezzo e se scontato -->
        <p class="price mt-3 mb-3" v-if="discountPrice">
          <span class="discounted"> {{ formatPrice(price) }} € </span>
          {{ formatPrice(discountPrice) }} €
          </p>
        <p class="price mt-3 mb-3" v-else>{{ formatPrice(price) }} €</p>
        <!-- prodotto con varianti -->
        <div v-if="variants.length !== 0" class="product__details__option__size">
          <h4 v-if="!available" class="text-bigger mb-4">Prodotto non disponibile</h4>
          <h4 v-if="available" class="text-bigger mb-2">Seleziona una taglia:</h4>
          <h4 v-if="!available" class="text-bigger mb-2">Seleziona una taglia per sapere quando torna disponibile:</h4>
          <form @submit.prevent="addToCart">
            <ul class="d-inline-block mb-2 list-unstyled" role="tablist">
              <template v-for="item in variants">
                <li class="nav-item">
                  <label id="selected_size" class="sizes" v-on:click="selectVariant($event, item)" :class="checkStock(item.id) > 0 ? null : 'not_available'">{{item.attributes[0].value.value }}</label>
                </li>
              </template>
            </ul>
            <!--form email avviso-->
            <div v-if="showMailForm" class="my-3">
              <p>Questa taglia è esaurita: ricevi una email quando torna in stock!</p>
              <form @submit.prevent="sendEmail()">
                <div class="input-group">
                  <input type="email" class="form-control" v-model="email" placeholder="Inserisci la tua email">
                  <button class="btn" type="submit">AVVISAMI</button>
                </div>
              </form>
            </div>
            <!--bottone carrello-->
            <div v-if="quantity > 0">
              <h4 class="text-bigger mt-4 mb-2">QUANTITÀ:</h4>
              <div class="pro-qty mb-2 d-inline-flex">
                <img src="/svg/minus-solid.svg" class="icon m-2" v-on:click="minusorplus('-')" alt>
                <input v-model="this.qnt" type="text" class="input_qnt" disabled>
                <img src="/svg/plus-solid.svg" class="icon m-2" v-on:click="minusorplus('+')" alt>
              </div>
              <br>
              <button type="submit" class="primary-btn mt-4">AGGIUNGI AL CARRELLO</button>
            </div>
          </form>
        </div>
        <!-- prodotto senza varianti -->
        <div v-if="variants.length === 0">
          <template v-if="available">
            <form @submit.prevent="addToCart">
              <h4 class="text-bigger mt-4 mb-2">QUANTITÀ:</h4>
              <div class="pro-qty mb-3">
                <img src="/svg/minus-solid.svg" class="icon m-2" v-on:click="minusorplus('-')" alt>
                <input v-model="this.qnt" type="text" class="input_qnt" disabled>
                <img src="/svg/plus-solid.svg" class="icon m-2" v-on:click="minusorplus('+')" alt>
              </div>
              <button type="submit" class="primary-btn mt-3">AGGIUNGI AL CARRELLO</button>
            </form>
          </template>
          <template v-else-if="!available">
            <h4 class="text-bigger mb-4">Prodotto non disponibile</h4>
            <div v-if="showMailForm" class="my-3">
              <div class="product__details__option__size">
                <p>Ricevi una email quando torna in stock!</p>
                <form @submit.prevent="sendEmail()">
                  <div class="input-group">
                    <input type="email" class="form-control" v-model="email" placeholder="Inserisci la tua email">
                    <button class="btn" type="submit">AVVISAMI</button>
                  </div>
                </form>
              </div>
            </div>
          </template>
        </div>
        <!-- preferito -->
        <div>
          <button class="link_favorites mt-3 mb-4" v-on:click="addToFavorites(product.id)" v-if="!checkFav">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 512 512">
              <path
                  d="M244 84L255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84C243.1 84 244 84.01 244 84L244 84zM255.1 163.9L210.1 117.1C188.4 96.28 157.6 86.4 127.3 91.44C81.55 99.07 48 138.7 48 185.1V190.9C48 219.1 59.71 246.1 80.34 265.3L256 429.3L431.7 265.3C452.3 246.1 464 219.1 464 190.9V185.1C464 138.7 430.4 99.07 384.7 91.44C354.4 86.4 323.6 96.28 301.9 117.1L255.1 163.9z"/>
            </svg>
            AGGIUNGI TRA I PREFERITI
          </button>
          <button class="link_favorites mt-3 mb-4" v-on:click="removeFromFavorites(product.id)" v-else>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 512 512">
              <path
                  d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/>
            </svg>
            AGGIUNTO TRA I PREFERITI
          </button>
        </div>
        <!-- descrizione -->
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button pg text-dark" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Descrizione
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                 data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p v-if="product.descriptionExtended" id="description" class="text"
                   v-html="product.descriptionExtended"></p>
                <p v-else class="text text-center"> Nessuna descrizione. </p>
              </div>
            </div>
          </div>
        </div>
        <!-- prodotti correlati -->
        <RelatedProducts v-if="category" :idCategory="category"/>
      </div>
    </div>
  </div>
</template>
<script>
import SliderImages from "@/components/SliderImages";
import RelatedProducts from "@/components/RelatedProducts.vue";
import axios from "axios";
import router from "@/router";
import {IVA, PATH_APP} from "@/constants/constants";
import {Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Swal from "sweetalert2";

export default {
  name: "ProductDetails",
  components: {SliderImages, RelatedProducts},
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  data() {
    return {
      email: "",
      idProduct: null,
      idVariant : null,
      cart: [],
      slugProduct: null,
      product: [],
      variants: [],
      images : [],
      variantName : null,//contiene il nome della variante
      category: null,
      stock: [],
      quantity: 0, //quantità nello stock
      qnt: 1, //quantità da aggiungere al carrello
      checkFav: false,
      advise: false,
      base: PATH_APP,
      available: false, //se un prodotto è esaurito
      showMailForm: false,
      maxProd : false,//massimo due prodotti nel carrello per ogni taglia
      price : null,
      discountPrice : null
    }
  },
  computed: {
    userCountry(){
      return this.$store.getters.getUserCountry;
    },
    user() {
      return this.$store.getters.getUser;
    }
  },
  beforeMount() {
    //vede se è id o meno; se è id rimane in idProduct, altrimenti in slugProduct
    let param = this.$route.params.param;
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    if (regexExp.test(param)) {
      this.idProduct = param;
    } else {
      this.slugProduct = param;
    }
    this.getProduct();
    //this.checkCartNV();
  },
  mounted() {
    if (this.user) {
      this.email = this.user.email;
    }
  },
  methods: {
    async getProduct() {
      this.$store.commit("showSpinner");
      await axios.post("products", {
        "start": 0,
        "limit": 1,
        "ids": this.idProduct ? [this.idProduct] : [],
        "slugProduct": this.slugProduct,
        "type": "website"
      }).then(response => {
        if (response.data.products.length) {
          this.idProduct = response.data.products[0]?.id;
          this.product = response.data.products[0];
          this.variants = this.product.variants;
          this.images = this.product.images;
          this.category = response.data.products[0]?.category.id;
          this.product.prices.filter((el) => el["type"] === 'price' ? this.price = el["value"] : this.discountPrice = el["value"]);
          this.stock = response.data.products[0]?.stocks;
          this.checkQnt(); //per vedere se è disponibile o no
          //this.getStock();
          this.checkFavourites();
          this.$store.commit("hideSpinner");
        } else {
          router.push({name: "not-found"});
        }
      }).catch((error) => {
        if (error.response.status === 404) {
          router.push({name: "not-found"});
        } else {
          console.log(error);
        }
      });
    },
    formEmail(event) {//funzione per fare apparire la form per l'avviso restock
      let selectedEl = document.getElementsByClassName('sizes');
      for (let i = 0; i < selectedEl.length; i++) {
        selectedEl[i].classList.remove('activeBorder');
      }
      event.currentTarget.classList.add('activeBorder');
      this.showMailForm = this.quantity === 0; //attivo la form per l'avviso email per le varianti
    },
    validateEmail(email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    async sendEmail() {
      if(this.email && this.validateEmail(this.email)) {
        await axios.post("notifyrestock", {
          "idproduct": this.idProduct,
          "idvariant": this.idVariant,
          "name": this.variantName || this.product.description,
          "email": this.email,
        }).then(() => {
          Swal.fire({
            title: "Ti verrà inviata un'email quando il prodotto o la taglia tornerà in stock",
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
          });
        }).catch((error) => {
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "Qualcosa è andato storto. Controlla i dati o riprova più tardi.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
          });
        });
      }
    },
    /*
    async checkCartNV() { // per i prodotti senza varianti controlla la quantità nel carrello che non sia maggiore di 2
      this.$store.commit("showSpinner");
      let idcart = localStorage.getItem("cartIdLaMora");
      if (idcart !== null) {
        let token = localStorage.getItem("token");
        await axios.get("cart?idcart=" + idcart, {
          headers: {Authorization: `Bearer ${token}`}
        }).then((resp) => {
          this.cart = resp.data.cart.lineItems;
          //per ogni prodotto, controllare se id = id dei prod nel carrello
          this.cart.forEach((item) => {
            //prodotti con variante
            if (item.idProduct === this.idProduct) {
              console.log(item.idVariant);
              console.log(item);
              console.log("QNT" + item.qnt);
              //controllo sulla quantità
              if (item.qnt === 2) {
                this.difference = 2 - item.qnt;
              } else {
                this.difference = 2 - item.qnt;
              }
            }
          });
          this.$store.commit("hideSpinner");
        }).catch((error) => {
          console.log(error);
        });
      }
    },
     */
    async selectVariant(event, variant) {
      //setto i parametri per la variante perché la variante è dinamica in base al click della selezione
      this.qnt = 1;
      this.idVariant = variant.id;
      this.variantName = variant.description;
      let stockvar = this.stock.filter(v => v.idProductVariant === variant.id);
      this.quantity = stockvar[0].quantity;
      //per fare apparire il bordo nero sulla taglia
      let selectedEl = document.getElementsByClassName('sizes');
      for (let i = 0; i < selectedEl.length; i++) {
        selectedEl[i].classList.remove('selected_size');
      }
      if (this.quantity > 0) {
        event.currentTarget.classList.add('selected_size');
      }
      this.formEmail(event);
    },
    async getStock() {
      await axios.post("/stocks", {
        "start": 0,
        "limit": 100,
        "idProduct": [this.idProduct],
      }).then(response => {
        this.stock = response.data.stocks;
        this.checkQnt(); //per vedere se è disponibile o no
      }).catch((error) => {
        console.log(error);
      });
    },
    checkQnt() {
      //se ha le varianti o è prodotto normale vedo se ci sono quantità in generale
      //for (let i = 0; i < this.stock.length; i++) {
      let length = this.variants.length ? this.variants.length : this.stock.length;//faccio questo perché le varianti in stock possono essere diverse dalle varianti in product
      for (let i = 0; i < length; i++) {
        if (this.stock[i].quantity) {
          this.available = true;
        }
      }
      // se non ha le varianti per il prodotto singolo
      if (this.variants.length === 0) {
        this.quantity = this.stock[0].quantity;
        this.showMailForm = this.quantity === 0; //attivo la form per l'avviso email
      }
    },
    checkStock(idvar) {
      if (this.stock.length > 0) {
        return (this.stock.filter(variant => variant.idProductVariant === idvar))[0].quantity;
      } else {
        return 0;
      }
    },
    formatPrice(value) {
      if(this.userCountry && (this.userCountry === "Svizzera" || this.userCountry === "Regno Unito (UK)")){
        //value = (value - ((value / 100) * IVA)).toFixed(2);
        value = (value / 1.22).toFixed(2);
      }
      return Number(value).toFixed(2).replace(".", ",");
    },
    async addToCart() {
      let idcart = localStorage.getItem("cartIdLaMora");
      this.maxProd = false;
      if (idcart !== null) {
        //il carrello è stato già creato
        let token = localStorage.getItem("token");
        await axios.get("cart?idcart=" + idcart, {
          headers: {Authorization: `Bearer ${token}`}
        }).then((resp) => {
          this.cart = resp.data.cart.lineItems;
          //per ogni prodotto, controllare se id = id dei prod nel carrello
          this.cart.forEach((item) => {
            //prodotti con variante
            if ((item.idProduct === this.idProduct && item.idVariant === this.idVariant) || (item.idProduct === this.idProduct)) {//l'id variante nel carrello è lo stesso
              //controllo sulla quantità
              if((item.qnt + this.qnt) > 2){
                this.maxProd = true;
                Swal.fire({
                  title: "Attenzione: non puoi inserire nel carrello più di due prodotti dello stesso tipo.",
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#674e42',
                });
              }else{
                this.maxProd = false;
              }
            }
          });
        });
        if(!this.maxProd && this.qnt <= 2) {
          //evento google tag manager
          this.$gtm.trackEvent({
            event: "add_to_cart",
            value: Number(this.price * this.qnt),
            currency : "EUR",
            items : [{
              "item_id" : this.product.id,
              "item_name" : this.product.name,
              "item_brand" : "La Mora Glamour",
              "discount" : 0,
              "currency" : "EUR",
              "price" : Number(this.price),
              "quantity" : this.qnt
            }]
          });
          this.$store.dispatch("addToCart", {
            "idProduct": this.product.id,
            "idVariant": this.idVariant,
            "qnt": this.qnt,
          });
          //add_to_cart
        }else{
          this.maxProd = true;
          Swal.fire({
            title: "Attenzione: non puoi inserire nel carrello più di due prodotti dello stesso tipo.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
          });
        }
      }else{
        if(this.qnt <= 2) {
          //il carrello non c'é
          this.$store.dispatch("addToCart", {
            "idProduct": this.product.id,
            "idVariant": this.idVariant,
            "qnt": this.qnt,
          });
          //add_to_cart
        }else{
          this.maxProd = true;
          Swal.fire({
            title: "Attenzione: non puoi inserire nel carrello più di due prodotti dello stesso tipo.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
          });
        }
      }
    },
    checkFavourites() {
      let favs = JSON.parse(localStorage.getItem("favsLaMora"));
      if (!favs || favs === "") {
        this.checkFav = false;
      } else {
        favs.indexOf(this.idProduct) >= 0 ? this.checkFav = true : this.checkFav = false;
      }
    },
    addToFavorites(idprod) {
      let favs = JSON.parse(localStorage.getItem("favsLaMora"));
      if (!favs || favs === "") {
        localStorage.setItem("favsLaMora", JSON.stringify([idprod]));
        this.$store.commit("setTotalFavProds", 1);
      } else {
        favs.indexOf(idprod) === -1 ? favs.push(idprod) : favs.pop(idprod);
        localStorage.setItem("favsLaMora", JSON.stringify(favs));
        this.$store.commit("setTotalFavProds", favs.length);
      }
      this.checkFavourites(idprod);
    },
    removeFromFavorites(idprod) {
      let favs = JSON.parse(localStorage.getItem("favsLaMora"));
      if (favs.indexOf(idprod) !== -1) {
        favs.pop(idprod)
        localStorage.setItem("favsLaMora", JSON.stringify(favs));
        this.$store.commit("setTotalFavProds", favs.length);
        this.checkFav = false;
      }
    },
    minusorplus(action) {
      switch (action) {
        case "+":
          if (this.qnt < this.quantity && this.qnt < 2) {
            this.qnt += 1;
          }
          break;
        case "-":
          if (this.qnt > 1) {
            this.qnt -= 1;
          }
          break;
      }
    },
  },
}

</script>

<style scoped>

.title {
  font-size: 25px;
  font-family: oswald;
}

.subtitle {
  font-style: normal;
}

.input-group .btn {
  background-color: #0b0b0b;
  color: whitesmoke;
}

.nav-item {
  list-style: none;
  display: inline;
}

.input_qnt {
  width: 70px;
  font-size: 17px;
  text-align: center;
  padding: 8px 5px 8px 5px;
  border: 1px solid #8c8b8b;
}

.product__details__last__option ul li span {
  color: black;
}

.link_favorites, .link_favorites:hover {
  background-color: white;
  color: black;
  font-size: 18px;
  font-weight: 400;
  border: none;
}

.product__details__option__size {
  margin-right: 0;
}

.discounted {
  color: grey;
  font-size: 18px;
  text-decoration-line: line-through;
}

.not_available { /* non eliminare */
  color: #a1a1a1;
  border-color: #e5e5e5;
  background-color: #f3f3f3;
}

.activeBorder { /* non eliminare */
  border-color: #a1a1a1;
}

.price {
  font-size: 25px;
}

.accordion {
  border: 1px solid #dedede;
}

.accordion-button:not(.collapsed) {
  background-color: #fff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

:global(input[type=number]::-webkit-inner-spin-button),
:global(input[type=number]::-webkit-outer-spin-button) {
  opacity: 1;
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1;
}

.selected_size { /* non cancellare */
  background-color: black;
  color: white;
}

.rp_bottom {
  position: absolute;
  bottom: 0;
}

.icon {
  width: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.card-body {
  height: 160px;
}

.accordion-header button {
  padding: 10px 100px 10px 100px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #dedede;
}

.accordion-header button:active,
.accordion-header button:focus {
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
}

ul li {
   margin-left: 0px;
}

@media (max-width: 576px) {

  .discounted {
    font-size: 16px;
  }

}
</style>