<template>
  <div class="mt-5 mb-5">
    <!-- prodotti correlati -->
    <p class="subtitle text-center">PRODOTTI CORRELATI</p>
    <swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :pagination="{clickable: true}" :navigation="true" :modules="modules"
            :breakpoints="{
              // when window width is >= 320px
              0: {
                slidesPerView: 2,
                spaceBetween: 15
              },
              // when window width is >= 480px
              569: {
                slidesPerView: 3,
                spaceBetween: 15
              },
              // when window width is >= 640px
              992: {
                slidesPerView: 4,
                spaceBetween: 15
              }
          }" class="mySwiper">
      <template v-for="relatedProduct in relatedProducts">
        <swiper-slide class="pt-2">
          <div class="cards-wrapper">
            <div class="card">
              <router-link :to="'/prodotto/' + relatedProduct.slug + '/'" class="related-block">
                <img v-if="relatedProduct.images[0]" :src="relatedProduct.images[0].imageUrl" class="card-img-top" alt>
                <img v-else class="image" :src="base + 'img/placeholder.png'" alt/>
                <div class="card-body">
                  <h5 class="text text-left"> {{ relatedProduct.description }} </h5>
                  <div class="rp_bottom">
                    <p class="text-italic mt2 mb-2" v-if="relatedProduct.prices?.[1]?.value">
                      {{ formatPrice(relatedProduct.prices?.[1]?.value) }} €
                      <span class="discounted"> {{ formatPrice(relatedProduct.prices?.[0]?.value) }} € </span>
                    </p>
                    <p v-else class="mt2 mb-2"> {{ formatPrice(relatedProduct.prices?.[0]?.value) }} €</p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>
<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import {IVA, PATH_APP} from "@/constants/constants";
import axios from "axios";
export default {
  name: "ProductDetails",
  props: {
    idCategory: {
      type: String
    }
  },
  components: {Swiper, SwiperSlide},
  computed:{
    userCountry(){
      return this.$store.getters.getUserCountry;
    },
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  data() {
    return {
      base : PATH_APP,
      relatedProducts : []
    }
  },
  beforeMount() {
    this.getRelatedProducts();
  },
  methods: {
    formatPrice(value) {
      if(this.userCountry && (this.userCountry === "Svizzera" || this.userCountry === "Regno Unito (UK)")){
        //value = (value - ((value / 100) * IVA)).toFixed(2);
        value = (value / 1.22).toFixed(2);
      }
      return Number(value)
          .toFixed(2)
          .replace(".", ",");
    },
    async getRelatedProducts() {
      await axios.post("products", {
        "start": 0,
        "limit": 12,
        "idsCategory": [this.idCategory],
        "type": "website"
      }).then(response => {
        this.relatedProducts = response.data.products;
      }).catch((error) => {
        console.log(error);
      });
    }
  }
}
</script>