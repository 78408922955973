<template>
  <div :class="[data.columnb]">
      <img v-for="image in data.files" :key="image.id" :src="image.url" :alt="image.alt" :class="[data.classes,'img-fluid']" />
    </div>
</template>

<script>
export default {
  name: "CmsImage",
  props: ['data'],
};
</script>

<style>

</style>

