<template>
  <!--<div class="row mt-5" v-if="this.pathimgvideo && this.pathvideo">
    <div class="col-lg-12 align-middle d-lg-flex justify-content-center">
      <img class="product-image img-fluid" v-if="images?.[slide]?.imageUrl" :src="images?.[slide]?.imageUrl" alt="">
      <video v-else width="100%" :poster="this.pathimgvideo" controls>
        <source :src="this.pathvideo" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
  </div>-->
  <div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        <swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :pagination="{clickable: true,  }"
                :modules="modules" :navigation="true"
                :breakpoints="{
                // when window width is >= 320px
                0: {
                  slidesPerView: 1,
                  spaceBetween: 15
                },
                // when window width is >= 480px
                569: {
                  slidesPerView: 1,
                  spaceBetween: 15
                },
                // when window width is >= 640px
                992: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                }
              }"
                class="mySwiper">
          <swiper-slide v-if="this.pathvideo">
            <div class="cards-wrapper">
              <div class="card">
                <video :poster="this.pathimgvideo" controls>
                  <source :src="this.pathvideo" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </swiper-slide>
          <template v-for="image in images">
            <swiper-slide>
              <div class="cards-wrapper">
                <div class="card">
                  <img v-if="image.imageUrl"
                       :src="image.imageUrl"
                       alt="">
                </div>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";

export default {
  name: "SliderImages",
  components: {Swiper, SwiperSlide},
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  props: {
    images: {
      type: Array
    },
    pathimgvideo: {
      type: String
    },
    pathvideo: {
      type: String
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>

<style scoped>

video, .product-image, .card, .swiper {
  width: 100% !important;
  max-width: 400px;
  height: auto !important;
}

img {
  max-width: 400px;
}

</style>