<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb__text">
            <h2 class="mt-3 title text-center">Termini e condizioni</h2>
            <span class="text">
              <br>
              Questi Termini disciplinano
              <br>
              • l’utilizzo di questa Applicazione e
              <br>
              • qualsiasi altro Accordo o rapporto giuridico con il Titolare
              <br>
              in maniera vincolante. Le espressioni con l’iniziale maiuscola sono definite nella relativa sezione di
              questo documento.
              <br>
              <br>
              L’Utente è pregato di leggere attentamente questo documento.
              <br>
              Il soggetto responsabile di questa Applicazione è:
              <br>
              <br>
              URANUS SRL
              <br>
              Via Nilo Mondin 18
              <br>
              37067 Valeggio sul Mincio VR
              <br>
              <br>
              <strong>Indirizzo email del Titolare: </strong>
              info@lamoraglamour.com
            </span>
            <br>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <br>
          <br>
          <h3><strong>Da sapere a colpo d’occhio</strong></h3>
          <hr>
          <br>
          <h3><strong>CONDIZIONI D'USO</strong></h3>
          <p class="text">
            <br>
            Salvo ove diversamente specificato, le condizioni d’uso di questa Applicazione esposte in questa sezione
            hanno validità generale.
            <br>
            Ulteriori condizioni d’uso o d’accesso applicabili in particolari situazioni sono espressamente indicate in
            questo documento.
            <br>
            Utilizzando questa Applicazione l’Utente dichiara di soddisfare i seguenti requisiti:
          </p>
          <h4><strong>Contenuti su questa Applicazione</strong></h4>
          <p class="text">
            <br>
            Salvo ove diversamente specificato o chiaramente riconoscibile, tutti i contenuti disponibili su questa
            Applicazione sono di proprietà di o forniti dal Titolare o dei/dai suoi licenzianti.
            <br>
            <br>
            Il Titolare adotta la massima cura affinché il contenuto disponibile su questa Applicazione non violi la
            normativa applicabile o diritti di terze parti. Tuttavia, non sempre è possibile raggiungere tale risultato.
            <br>
            In tali casi, senza alcun pregiudizio ai diritti ed alle pretese legalmente esercitabili, gli Utenti sono
            pregati di indirizzare i relativi reclami ai recapiti specificati in questo documento.
          </p>
          <h4><strong>Accesso a risorse esterne</strong></h4>
          <p class="text">
            <br>
            Tramite questa Applicazione gli Utenti potrebbero avere accesso a risorse fornite da terzi. Gli Utenti
            riconoscono ed accettano che il Titolare non ha alcun controllo su tali risorse e che pertanto non risponde
            del loro contenuto e della loro disponibilità.
            <br>
            <br>
            Le condizioni applicabili alle risorse fornite da terzi, ivi incluse quelle applicabili a eventuali
            concessioni di diritti su contenuti, sono determinate dagli stessi terzi e regolate nei relativi termini e
            condizioni o, in loro assenza, dalla legge.
          </p>
          <h4><strong>Uso ammesso</strong></h4>
          <p class="text">
            <br>
            Questa Applicazione ed il Servizio possono essere utilizzati solo per gli scopi per i quali sono offerti,
            secondo questi Termini ed ai sensi della legge applicabile.
            <br>
            È responsabilità esclusiva dell’Utente di far sì che l’uso di questa Applicazione e/o del Servizio non violi
            la legge, i regolamenti o i diritti di terzi.
          </p>
          <br>
          <br>
          <h3><strong>Disposizioni comuni</strong></h3>
          <br>
          <h4><strong>Nessuna rinuncia implicita</strong></h4>
          <p class="text">
            <br>
            Il mancato esercizio di diritti di legge o pretese derivanti da questi Termini da parte del Titolare non
            costituisce rinuncia agli stessi. Nessuna rinuncia può essere considerata definitiva in relazione ad uno
            specifico diritto o a qualsiasi altro diritto.
          </p>
          <h4><strong>Interruzione del Servizio</strong></h4>
          <p class="text">
            <br>
            Per garantire il miglior livello di servizio possibile, il Titolare si riserva di interrompere il Servizio
            per finalità di manutenzione, aggiornamenti di sistema o per qualsiasi altra modifica, dandone idonea
            notizia agli Utenti.
            <br>
            Nei limiti di legge, il Titolare si riserva di sospendere o terminare completamente il Servizio. In caso di
            terminazione del Servizio, il Titolare si adopererà affinché gli Utenti possano estrarre i propri Dati
            Personali e le informazioni secondo le disposizioni di legge.
            <br>
            Inoltre, il Servizio potrebbe non essere disponibile per cause che si sottraggono al ragionevole controllo
            del Titolare, quali cause di forza maggiore (p. es. scioperi, malfunzionamenti infrastrutturali, blackout
            etc.).
          </p>
          <h4><strong>Rivendita del Servizio</strong></h4>
          <p class="text">
            <br>
            Gli Utenti non sono autorizzati a riprodurre, duplicare, copiare, vendere, rivendere o sfruttare questa
            Applicazione o il Servizio in toto o in parte senza previo consenso scritto del Titolare, espresso
            direttamente o attraverso un legittimo programma di rivendite.
          </p>
          <h4><strong>Interruzione del Servizio</strong></h4>
          <p class="text">
            <br>
            Senza pregiudizio ad alcuna previsione più specifica contenuta nei Termini, i diritti di proprietà
            intellettuale ed industriale, quali ad esempio diritti d’autore, marchi, brevetti e modelli relativi a
            questa Applicazione sono detenuti in via esclusiva dal Titolare o dai suoi licenzianti e sono tutelati ai
            sensi della normativa e dei trattati internazionali applicabili alla proprietà intellettuale.
            <br>
            Tutti i marchi – denominativi o figurativi – ed ogni altro segno distintivo, ditta, marchio di servizio,
            illustrazione, immagine o logo che appaiono in collegamento con questa Applicazione sono e restano di
            esclusiva proprietà del Titolare o dei suoi licenzianti e sono tutelati ai sensi della normativa e dei
            trattati internazionali applicabili alla proprietà intellettuale.
            <br>
          </p>
          <h4><strong>Modifiche dei Termini</strong></h4>
          <p class="text">
            <br>
            Il Titolare si riserva il diritto di modificare i Termini in ogni momento. In tal caso, il Titolare darà
            opportuna notizia delle modifiche agli Utenti.
            <br>
            Le modifiche avranno effetti sul rapporto con l’Utente solo per il futuro.
            <br>
            L’utilizzo continuato del Servizio implica l’accettazione dell’Utente dei Termini aggiornati. Se l’Utente
            non desidera accettare le modifiche, deve cessare l’utilizzo del Servizio. La mancata accettazione dei
            Termini aggiornati potrebbe comportare la facoltà di ciascuna parte di recedere dall’Accordo.
            <br>
            La versione precedente applicabile continua a disciplinare il rapporto fino all’accettazione dell’Utente.
            Tale versione può essere richiesta al Titolare.
            <br>
          </p>
          <h4><strong>Cessione del contratto</strong></h4>
          <p class="text">
            <br>
            Il Titolare si riserva il diritto di trasferire, cedere, disporre di, novare o appaltare singoli o tutti i
            diritti e le obbligazioni secondo questi Termini, avendo riguardo per gli interessi legittimi degli Utenti.
            <br>
            Si applicano le disposizioni relative alla modifica di questi Termini.
            <br>
            L’Utente non è autorizzato a cedere o trasferire i propri diritti e le proprie obbligazioni secondo i
            Termini senza il consenso scritto del Titolare.
            <br>
          </p>
          <h4><strong>Contatti</strong></h4>
          <p class="text">
            <br>
            Tutte le comunicazioni inerenti all’uso di questa Applicazione devono essere inviate ai recapiti indicati
            in questo documento.
            <br>
          </p>
          <h4><strong>Clausola di salvaguardia</strong></h4>
          <p class="text">
            <br>
            Qualora alcuna delle disposizioni di questi Termini dovesse essere o divenire nulla o inefficace ai sensi
            della legge applicabile, la nullità o inefficacia di tale disposizione non provoca inefficacia delle
            restanti previsioni, che permangono pertanto valide ed efficaci.
            <br>
          </p>
          <br>
          <br>
          <hr>
          <h3><strong>Definizioni e riferimenti legali</strong></h3>
          <br>
          <strong>Questa Applicazione (o questa Applicazione)</strong>
          <p class="text">La struttura che consente la fornitura del Servizio.</p>
          <strong>Accordo</strong>
          <p class="text" >Qualsiasi rapporto legalmente vincolante o contrattuale tra il Titolare e l’Utente disciplinato dai
            Termini. </p>
          <strong>Titolare (o Noi)</strong>
          <p class="text">Indica la persona fisica o giuridica che fornisce questa Applicazione e/o offre il Servizio agli Utenti.</p>
          <strong>Servizio</strong>
          <p class="text">Il servizio offerto tramite questa Applicazione così come descritto nei Termini e su questa Applicazione.</p>
          <strong>Termini</strong>
          <p class="text">Tutte le condizioni applicabili all’utilizzo di questa Applicazione e/o alla fornitura del Servizio
            così come descritti in questo documento nonché in qualsiasi altro documento o accordo ad esso
            collegato, nella versione rispettivamente più aggiornata.</p>
          <strong>Utente (o Tu)</strong>
          <p class="text">Indica qualsiasi persona fisica che utilizzi questa Applicazione.</p>
          <hr>
          <p class="final_notes">
            Ultima modifica: 10 maggio 2021
            <br>
            iubenda ospita questo contenuto e raccoglie solo i Dati Personali strettamente necessari alla sua fornitura.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "TerminiECondizioni"
}
</script>
<style scoped>
.final_notes {
  color: grey;
  font-size: 12px;
}
h3, h4 {
  font-family: oswald;
}
</style>