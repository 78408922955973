<template>
  <div class="container">
    <h4 class="title text-center mb-3">PROFILO</h4>
    <div class="row" v-if="editing === false">
      <div class="col-lg-6">
        <h4 class="subtitle text-center title-p">I tuoi dati anagrafici</h4>
        <div class="checkout__input">
          <p class="input-name">Nome</p>
          <p class="user_field"> {{user.name}} </p>
        </div>
        <div class="checkout__input">
          <p class="input-name">Cognome</p>
          <p class="user_field"> {{ user.surname }} </p>
        </div>
        <div class="checkout__input">
          <p class="input-name">Email</p>
          <p class="user_field"> {{ user.email }} </p>
        </div>
        <div class="checkout__input">
          <p class="input-name">Password</p>
          <p class="user_field">*********</p>
        </div>
        <div class="checkout__input" v-if="user.phone">
          <p class="input-name">Telefono</p>
          <p class="user_field"> {{ user.phone }} </p>
        </div>
      </div>
      <div class="col-lg-6">
        <h4 class="subtitle text-center title-p">Le tue misure</h4>
        <div class="checkout__input">
          <p class="input-name">Altezza in cm</p>
          <p class="user_field"> {{ user.heightcm }} cm</p>
        </div>
        <div class="checkout__input">
          <p class="input-name">Circonferenza seno in cm</p>
          <p class="user_field"> {{ user.breastcm }} cm</p>
        </div>
        <div class="checkout__input">
          <p class="input-name">Circonferenza glutei in cm</p>
          <p class="user_field"> {{ user.sitcm }} cm</p>
        </div>
        <div class="checkout__input">
          <p class="input-name">Punto vita in cm</p>
          <p class="user_field"> {{ user.waistcm }} cm</p>
        </div>
      </div>
      <div class="col-lg-12">
        <h4 class="subtitle text-center title-p">Il tuo indirizzo</h4>
        <div class="row" v-if="user.address && user.numciv && user.country &&
         user.city && user.prov && user.cap">
          <div class="col-lg-6">
            <div class="checkout__input">
              <p class="input-name">Via</p>
              <p class="user_field"> {{ user.address }} </p>
            </div>
            <div class="checkout__input">
              <p class="input-name">Numero civico</p>
              <p class="user_field"> {{ user.numciv }} </p>
            </div>
            <div class="checkout__input">
              <p class="input-name">Paese</p>
              <p class="user_field"> {{ user.country }} </p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="checkout__input">
              <p class="input-name">Città</p>
              <p class="user_field"> {{ user.city }} </p>
            </div>
            <div class="checkout__input">
              <p class="input-name">Provincia</p>
              <p class="user_field"> {{ user.prov }} </p>
            </div>
            <div class="checkout__input">
              <p class="input-name">CAP</p>
              <p class="user_field"> {{ user.cap }} </p>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-12">
            <p class="user_field">Non hai ancora inserito un indirizzo.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 text-center">
        <button type="button" class="primary-btn mt-4 mb-4" v-on:click="editProfile"> MODIFICA PROFILO</button>
      </div>
    </div>
    <div class="row" v-if="editing === true">
      <div class="col-lg-12" v-if="errors.length > 0">
        <div class="alert alert-danger mt-3" v-for="error in errors">
          {{ error }}
        </div>
      </div>
      <div class="col-lg-6">
        <h4 class="subtitle text-center title-p">I tuoi dati anagrafici</h4>
        <div class="checkout__input">
          <p class="input-name">Nome</p>
          <input type="text" class="user_field" v-model="user.name" :placeholder=[user.name]>
        </div>
        <div class="checkout__input">
          <p class="input-name">Cognome</p>
          <input type="text" class="user_field" v-model="user.surname" :placeholder=[user.surname]>
        </div>
        <div class="checkout__input">
          <p class="input-name">Email</p>
          <input type="email" class="user_field" v-model="user.email" :placeholder=[user.email]>
        </div>
        <div class="checkout__input">
          <p class="input-name">Password</p>
          <div class="d-inline-flex w-100 border" style="border-radius: 5px" v-if="this.seePwd === false">
            <input type="password" placeholder="Inserire la password per salvare le modifiche" class="user_field border-0" required v-model="this.user.password">
            <img v-on:click="seePassword" src="/svg/eye-solid.svg" class="pwd-eye">
          </div>
          <div class="d-inline-flex w-100 border" style="border-radius: 5px" v-if="this.seePwd === true">
            <input type="text" placeholder="Inserire la password per salvare le modifiche" class="user_field border-0" required v-model="this.user.password">
            <img v-on:click="seePassword" src="/svg/eye-slash-solid.svg" class="pwd-eye">
          </div>
        </div>
        <div class="checkout__input">
          <p class="input-name">Telefono</p>
          <input
              type="text"
              class="user_field input-field"
              v-model="user.phone"
              :placeholder=[user.phone]
          />
        </div>
      </div>
      <div class="col-lg-6">
        <h4 class="subtitle text-center title-p">Le tue misure</h4>
        <div class="checkout__input">
          <p class="input-name">Altezza in cm</p>
          <input
              type="number"
              class="user_field"
              v-model="user.heightcm"
              placeholder="Inserire un numero senza , o ."
          />
        </div>
        <div class="checkout__input">
          <p class="input-name">Circonferenza seno in cm</p>
          <input
              type="number"
              class="user_field"
              v-model="user.breastcm"
              placeholder="Inserire un numero senza , o ."
          />
        </div>
        <div class="checkout__input">
          <p class="input-name">Circonferenza glutei in cm</p>
          <input
              type="number"
              class="user_field"
              v-model="user.sitcm"
              placeholder="Inserire un numero senza , o ."
          />
        </div>
        <div class="checkout__input">
          <p class="input-name">Punto vita in cm</p>
          <input
              type="number"
              class="user_field"
              v-model="user.waistcm"
              placeholder="Inserire un numero senza , o ."
          />
        </div>
      </div>
      <div class="col-lg-12">
        <h4 class="subtitle text-center title-p">Il tuo indirizzo</h4>
        <div class="row">
          <div class="col-lg-6">
            <div class="checkout__input">
              <p class="input-name">Via</p>
              <input type="text" class="user_field" v-model="user.address">
            </div>
            <div class="checkout__input">
              <p class="input-name">Numero civico</p>
              <input type="text" class="user_field" v-model="user.numciv">
            </div>
            <div class="checkout__input">
              <p class="input-name">Paese</p>
              <select class="form-control" id="shipping_country" v-model="user.country">
                <option v-for="country in this.countries" :value="country">
                  {{ country }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="checkout__input">
              <p class="input-name">Città</p>
              <input type="text" class="user_field" v-model="user.city">
            </div>
            <div class="checkout__input">
              <p class="input-name">Provincia</p>
              <input type="text" class="user_field" maxlength="2" v-model="user.prov">
            </div>
            <div class="checkout__input">
              <p class="input-name">CAP</p>
              <input type="text" class="user_field" v-model="user.cap">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 text-center">
        <p class="text font-weight-bold mt-3"> Per completare la modifica del profilo, ricordati di reinserire la PASSWORD.</p>
        <button type="button" class="primary-btn mb-3" v-on:click="checkForm">SALVA MODIFICHE DEL PROFILO</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ProfileData",
  data() {
    return {
      editing: false,
      errors: [],
      seePwd: false,
      countries: [
        "Italia",
        "Austria",
        "Belgio",
        "Germania",
        "Lussemburgo",
        "Paesi Bassi",
        "Slovenia",
        "Danimarca",
        "Francia",
        "Regno Unito (UK)",
        "Croazia",
        "Polonia",
        "Repubblica Ceca",
        "Serbia",
        "Spagna",
        "Svizzera",
        "Ungheria",
        "Bulgaria",
        "Estonia",
        "Irlanda",
        "Lettonia",
        "Lituania",
        "Portogallo",
        "Romania",
        "Slovacchia",
        "Svezia",
        "Grecia",
        "San Marino"
      ],
    };
  },
  computed : {
    user(){
      return this.$store.getters.getUser;
    }
  },
  beforeMount() {
    //riprendo i dati dal server
    this.$store.dispatch("getProfileData");
  },
  methods: {
    editProfile() {
      this.editing = true;
    },
    checkForm() {
      this.errors = [];
      if (!this.user.name || !this.user.surname || !this.user.email || !this.user.password
          || !this.user.heightcm ||  !this.user.waistcm || !this.user.sitcm ||
          !this.user.breastcm || !this.user.country || !this.user.address || !this.user.numciv || !this.user.city ||
          !this.user.prov || !this.user.cap || !this.user.phone) {
        window.scrollTo(0, 0);
        this.errors.push("Non hai inserito alcuni campi obbligatori.");
      }
      if (this.user.prov.length > 2 || this.user.prov.length < 2) {
        window.scrollTo(0, 0);
        this.errors.push('Il campo provincia deve avere 2 caratteri.');
      }
      if (this.errors.length === 0) {
        this.saveEdits();
      }
    },
    seePassword() {
      if (this.seePwd) {
        this.seePwd = false;
      } else {
        this.seePwd = true;
      }
    },
    async saveEdits() {
      this.$store.commit("showSpinner");
      let token = localStorage.getItem("token");
      await axios
          .put(
              "editprofile",
              {
                name: this.user.name,
                surname: this.user.surname,
                email: this.user.email,
                password: this.user.password,
                phone: this.user.phone,
                country: this.user.country,
                address: this.user.address,
                numciv: this.user.numciv,
                cap: this.user.cap,
                city: this.user.city,
                prov: this.user.prov,
                heightcm: this.user.heightcm,
                breastcm: this.user.breastcm,
                sitcm: this.user.sitcm,
                waistcm: this.user.waistcm,
              },
              {
                headers: {Authorization: `Bearer ${token}`},
              }
          ).then((resp) => {
            this.$store.commit("hideSpinner");
            this.$store.commit("SET_USER",resp.data);
            localStorage.setItem("user", JSON.stringify(resp.data));
            this.editing = false;
          }).catch((error) => {
            this.$store.commit("hideSpinner");
            if (error.response.status === 400) {
              Swal.fire({
                title: error.response.data.error
                    ? error.response.data.error
                    : "Errore. Riprovare più tardi o ricontrollare i dati immessi.",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#674e42",
              });
              console.log(error);
            } else {
              console.log(error);
            }
          });
    }
  },
};
</script>

<style scoped>
.subtitle {
  font-style: normal;
}

.pwd-eye {
  width: 20px;
  margin-right: 10px;
}

.input-name {
  font-size: 20px;
  color: #000;
  font-family: oswald;
  padding-top: 10px;
  margin-bottom: 3px;
}

.user_field {
  font-family: opensans;
  font-size: 18px;
  border-radius: 5px
}

@media (max-width: 576px) {
  .subtitle {
    font-size: 25px;
  }
  .title-p {
    margin: 20px 0;
  }
  .user_field {
    font-family: opensans-light;
    font-size: 18px;
    border-radius: 5px
  }
}

</style>
