<template>
  <template v-if="maintenance">
    <Maintenance/>
  </template>
  <template v-else>
    <Spinner/>
    <Header v-if="this.$store.state.menus.length > 0" :menus="headerMenu" :sidebarmenu="sidebarMenu"/>
    <div class="container wrapper" v-if="!this.$store.state.opened">
      <router-view :key="$route.path"/>
      <PageView :page-data="pageData"/>
    </div>
    <Footer v-if="!this.$store.state.opened"/>
    <ScrollTop/>
  </template>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Spinner from '@/components/Spinner'
import ScrollTop from "@/components/ScrollTop";
import Maintenance from "@/views/Maintenance";
import axios from "axios";
import PageView from "@/views/PageView.vue";
import {BASE_URL_CMS} from "@/constants/constants";

export default {
  name: 'App',
  components: {
    PageView,
    ScrollTop,
    Spinner,
    Header,
    Footer,
    Maintenance
  },
  data() {
    return {
      maintenance : false,
      pageData: [],
    }
  },
  created() {
    this.fetchAndCreateRoutes();
    this.$store.dispatch("fetchMenus");
  },
  beforeMount() {
    this.getMaintenance();
    this.verifyUser();
  },
  methods : {
    async fetchAndCreateRoutes() {
      try {
        const response = await axios.get(
            BASE_URL_CMS + "/components",
        );
        const data = response.data.components;
        // Aggiorna la variabile pageData con i dati ottenuti
        this.pageData = data;
        // Crea route per ogni pagina
        data.forEach(page => {
         // console.log(page.components);
          const slug = page.slug;
          //console.log("page.components",page.components);
          //imposto la route con il nome della pagina che visito, gli passo gli hooks associati, il path alla pagina che voglio caricare
          const route = {
            path: `${slug}`,
            component: PageView,
            //oltre ai componenti della pagina passo anche il title della pagina cosi cambia in modo dinamico
            props: (route) => ({pageData: page.components, pageTitle: route.meta.title}),
            name: slug,
            meta: {
              auth: false,
              title: page.title,
              description: page.description,
            },
          };
          this.$router.addRoute(route);
        });
        //console.log("Tutte le Routes", this.$router.getRoutes());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getMaintenance() {
      await axios.get("maintenance",{}).then((resp) => {
        this.maintenance = resp.data.active;
        //this.ip = "";
      }).catch((error) => {
        console.log(error);
      });
    },
    async verifyUser() {
      let token = localStorage.getItem("token");
      if(token !== null) {
        await axios.get("verifyuser", {
          headers: {Authorization: `Bearer ${token}`}
        }).then((resp) => {
          if (!resp.data.user.refreshtoken) {
            this.$store.dispatch("logout");
          }else{
            this.$store.commit("SET_USER",resp.data.user);
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    }
  },
  computed: {
    // Filtra i menu
    headerMenu() {
      return this.$store.state.menus ? this.$store.state.menus.find(menu => menu.name === "Header") : null;
    },
    sidebarMenu() {
      return this.$store.state.menus ? this.$store.state.menus.find(menu => menu.name === "Sidebar") : null;
    },
    footerMenu() {
      return this.$store.state.menus ? this.$store.state.menus.find(menu => menu.name === "Footer") : null;
    }
  }
}
</script>
<style scoped>


:global(input[type=number]::-webkit-inner-spin-button),
:global(input[type=number]::-webkit-outer-spin-button) {
  opacity: 1;
}

@font-face {
  font-family: opensans;
  src: url('../public/fonts/OpenSans-Regular.ttf');
}
@font-face {
  font-family: opensans-light;
  src: url('../public/fonts/OpenSans-Light.ttf');
}
@font-face {
  font-family: oswald;
  src: url('../public/fonts/Oswald-Regular.ttf');
}
@font-face {
  font-family: oswald-light;
  src: url('../public/fonts/Oswald-Light.ttf');
}

.wrapper {
  width: 100%;
  overflow: hidden;
  padding: 150px 0 0 0;
  min-height: 600px;
}

:global(.swiper-button-prev), :global(.swiper-button-next) {
  color: white;
  padding: 25px;
  background: #282828;
  border-radius: 50%;
}

:global(.swiper-button-prev::after), :global(.swiper-button-next::after) {
  font-size: 20px;
}

:global(.swiper-pagination-bullet-active) {
  background: #282828;
}

:global(.swiper-pagination) {
  position: relative;
  bottom: 0;
}
:global(.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal) {
  bottom: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active{
  border: 0 !important;
}

@media (max-width: 767px) {


    .wrapper {
      padding: 100px 0 0 0;
    }
  /*:global(.swiper-button-prev), :global(.swiper-button-next) {
    display: none;
  }*/
}
</style>
