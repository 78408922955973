<template>
  <div class="container">
    <p class="title text-center"> {{ formatName(slugName) }} </p>
    <template v-bind:key="post.id" v-for="post in posts">
      <router-link :to="'/post/' + post.id + '/'">
        <div class="media">
          <img class="mr-3 img-blog my-auto" :src="this.image" alt="">
          <div class="media-body">
            <p class="post_categories d-inline-flex pr-3" v-bind:key="cat.id" v-for="cat in cats"> {{ cat.name }} </p>
            <p class="post_title"> {{ post.title.rendered }} </p>
            <p class="post_tags d-inline-flex pr-1" v-bind:key="tag.id" v-for="tag in tags"> # {{ tag.name }} </p>
          </div>
        </div>
      </router-link>
      <hr style="border-top: 1px solid #eaeaea;">
    </template>
  </div>
</template>

<script>
import axios from "axios";
import {WP_BLOG} from "@/constants/constants";

export default {
  name: "FilteredPosts",
  data() {
    return {
      tags: [],
      cats: [],
      isTag: false,
      slugName: "",
      slugFilter: 0,
      posts: [],
      postID: "",
      imageID: "",
      image: "",
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.slugFilter = this.$route.params.slug;
    this.getIDSlug(this.slugFilter);
  },
  methods: {
    async getIDSlug(slug) {
      this.$store.commit("showSpinner");
      //definire se è un tag o una categoria
      if ((this.$route.path).includes("category")) {
        this.isTag = false;
        await axios
            .get(WP_BLOG + "categories", {
              params: {
                "slug": slug,
              }
            })
            .then(response => {
              console.log("CAT", response.data);
              this.slugName = response.data[0].name;
              this.slugFilter = response.data[0].id;
              this.getPostsByFilter(this.slugFilter);
            })
      } else if ((this.$route.path).includes("tag")) {
        this.isTag = true;
        await axios
            .get(WP_BLOG + "tags", {
              params: {
                "slug": slug,
              }
            })
            .then(response => {
              this.slugName = response.data[0].name;
              this.slugFilter = response.data[0].id;
              this.getPostsByFilter(this.slugFilter);
            })
      }
      this.$store.commit("hideSpinner");
    },
    async getPostsByFilter(slugFilter) {
      if (this.isTag) {
        await axios
            .get(WP_BLOG + "posts", {
              params: {
                "tags": slugFilter,
              }
            })
            .then(response => {
              this.posts = response.data;
              this.postID = response.data[0].id;
            })
      } else {
        await axios
            .get(WP_BLOG + "posts", {
              params: {
                "categories": slugFilter,
              }
            })
            .then(response => {
              this.posts = response.data;
              this.postID = response.data[0].id;
              console.log("P", this.posts);
            })
      }
      this.getTagsPost(this.postID);
      this.getCatsPost(this.postID);
      /* prendere l'immagine */
      if (this.posts) {
        await axios
            .get(WP_BLOG + "media", {
              params: {
                "id": this.postID,
              }
            })
            .then(response => {
              this.image = response.data[0].source_url;
            })
      }
      this.$store.commit("hideSpinner");
    },
    formatName(value) {
      return String(value).replace("&amp;", "&");
    },
    async getTagsPost(id) {
      await axios
          .get(WP_BLOG + "tags", {
            params: {
              "post": id,
            }
          })
          .then(response => {
            this.tags = response.data;
          })
    },
    async getCatsPost(id) {
      await axios
          .get(WP_BLOG + "categories", {
            params: {
              "post": id,
            }
          })
          .then(response => {
            this.cats = response.data;
          })
    }
  }
}
</script>

<style scoped>
.img-blog {
  max-width: 200px;
}

.post_title {
  font-size: 20px;
  text-align: left;
}

.post_tags {
  color: grey;
  text-align: left;
}

.post_categories {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: left;
}

@media (max-width: 576px) {
  .title {
    margin-top: 30px;
  }

  .img-blog {
    max-width: 100px;
  }


}
</style>