<template>
  <div class="container">
    <div class="row bottomheader">
      <div class="col-lg-3 col-md-2 text-center m-auto" v-if="!opened">
        <router-link to="/">
          <img src="/img/la-mora-logo.png" alt="logo icon" class="logo">
        </router-link>
      </div>
      <div class="col-lg-6 col-md-7 header__menu " v-if="!opened">
        <ul>
          <div class="header__menu " v-if="!opened">
            <ul>
              <li v-for="item in menus.menuitems" :key="item.id" >
                <router-link :to="item.link">{{item.name}}</router-link>
              </li>
            </ul>
          </div>
        </ul>
      </div>
      <div class="col-lg-3 col-md-3 justify-content-end d-flex mt-4">
        <div class="d-inline-flex">
          <template v-if="auth === false">
            <router-link to="/login" class="icon_home" style="margin-top: 2px" v-if="!opened">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
              </svg>
            </router-link>
          </template>
          <template v-else>
            <router-link to="/profile" class="icon_home" style="margin-top: 2px" v-if="!opened">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8
                304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7
                304H178.3z"/>
              </svg>
            </router-link>
          </template>
          <svg v-on:click="openSearch()" style="margin-top: 5px; height: 22px;" class="icon_home margin-search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-if="!opened">
            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
          </svg>
          <router-link to="/cart" class="icon_home" v-if="!opened">
            <img src="/svg/bag-shopping-solid.svg" class="icon_mobilehome" alt="">
            <p class="badgecart" v-if="cartProds">{{ cartProds }}</p>
          </router-link>
          <router-link to="/favourites" class="icon_home ml-2" v-if="!opened">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3
                47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244
                84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/>
            </svg>
            <p class="badgecart" v-if="favProds">{{ favProds }}</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import Sidebar from "@/components/Sidebar";
import Search from "@/components/Search";

export default {
// eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  components: {Sidebar, Search},
  props: ['menus', 'sidebarmenu'],
  computed: {
    routePath() {
      return this.$route.path;
    },
    cartProds() {
      return this.$store.getters.getCartTotalProducts;
    },
    favProds() {
      return this.$store.getters.getFavTotalProducts;
    },
    opened(){
      return this.$store.getters.getOpened;
    },
    user(){
      return this.$store.getters.getUser;
    },
    auth(){
      return this.$store.getters.getAuth;
    }
  },
  data() {
    return {
      favProducts: []
    }
  },
  mounted() {
    this.getTotalProdsCart();
    this.getTotalProdsFav();
  },
  methods: {
    openSearch() {
      this.$store.commit("setOpened",true);
    },
    logout() {
      this.$store.dispatch("logout");
    },
    async getTotalProdsCart() {
      let idcart = localStorage.getItem("cartIdLaMora");
      if (idcart !== null) {
        let token = localStorage.getItem("token");
        await axios.get("cart?idcart=" + idcart, {
          headers: {Authorization: `Bearer ${token}`}
        }).then(resp => {
          this.$store.commit('SET_TOTAL_PRODSCART', resp.data.totalProdsCart);
        }).catch(function (error) {
          if (error.response.status === 404) {
            localStorage.removeItem("cartIdLaMora");
          } else {
            console.log(error);
          }
        });
      }
    },
    async getTotalProdsFav() {
      if (localStorage.getItem("favsLaMora") && localStorage.getItem("favsLaMora") !== null) {
        this.favProducts = JSON.parse(localStorage.getItem("favsLaMora"));
        if (this.favProducts.length !== 0) {
          await axios.post("/products/", {
                "start": 0,
                "limit": 100,
                "ids": this.favProducts,
              },
          ).then(response => {
            //this.$store.commit("setFavsProducts", response.data.products); non serve
            this.$store.commit("setTotalFavProds", response.data.totalCount);
          });
        }
      }
    }
  },
}

</script>

<style scoped>
.search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.bottomheader {
  margin: 10px 0 10px 0;
  padding: 0;
  background-color: white;
}

.logo {
  width: 60%;
}

.header__menu {
  margin: auto;
  justify-content: space-around;
  display: flex;
}

.header__menu ul li a {
  font-size: 20px;
  font-family: opensans-light;
  font-weight: 500;
}

.header__menu ul li {

  list-style: none;
  display: inline-block;
}

.icon_home {
  width: 22px;
  margin-right: 25px;
}

.margin-search {
  margin-bottom: 30px;
}

.icone_notlogged a, .icone_logged a {
  margin: 0 15px 0 15px;
}

.badgecart {
  background-color: black;
  border-radius: 50%;
  color: white;
  position: relative;
  top: -30px;
  left: 19px;
  margin-left: 3px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

/*Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1200px) {

  .logo {
    width: 70%;
  }

  .header__menu ul li a {
    font-size: 18px;
    font-family: opensans-light;
    font-weight: 500;
  }

  .header__menu ul li {
    margin-right: 20px;
  }

  .icon_home {
    width: 20px;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991px) {

  .col-md-2 {
    padding-right: 0;
  }

  .logo {
    width: 100%;
  }

  .header__menu ul li a {
    font-size: 16px;
    font-weight: 400;
  }

  .header__menu ul li:not(:last-child) {
    margin-right: 20px;
  }

  .icon_home {
    width: 18px;
    margin-right: 17px;
  }

  .margin-search {
    margin-top: 5px;
  }

  .badgecart {
    margin-left: 18px;
    font-size: 10px;
  }
}

</style>
