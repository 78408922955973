<template>
  <div class="container">
    <div class="row" v-if="allorders.length > 0">
      <h4 class="title text-center">ORDINI</h4>
      <div class="col-lg-12">
        <h4 class="subtitle text-center title-p">I tuoi ordini</h4>
        <div v-for="order in allorders" class="singlebox mb-4">
          <div class="row d-flex justify-content-between topcard">
            <p class="text-bigger font-weight-bolder"> Ordine del: {{ formatDate(order.created_at) }}</p>
            <!--<p class="text-bigger font-weight-bolder"> Totale: {{ order.total }} €</p>-->
          </div>
          <hr>
          <div class="row">
            <div class="col-lg-12 col-12 text-center">
              <p class="text-bigger font-weight-bolder mt-3"><strong> NUMERO D'ORDINE </strong>  </p>
              <p class="text mb-3"> {{ order.idorder }} </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 text-center">
              <p class="text-bigger font-weight-bolder mt-3"><strong> INDIRIZZO DI SPEDIZIONE </strong></p>
              <p class="text mb-3"> Indirizzo: {{ order.address }}, {{ order.numciv }}
                {{ order.country }}, {{ order.city }} ({{ order.cap }})
              </p>
            </div>
            <div class="col-lg-6 col-12 text-center justify-content-center">
              <p class="text-bigger font-weight-bolder mt-3 pr-2"><strong> TIPO DI PAGAMENTO </strong> </p>
              <p class="text mb-3" v-if="order.payment.type === 'bank'"> Bonifico bancario </p>
              <p class="text mb-3" v-if="order.payment.type === 'stripe'"> Carta di credito </p>
              <p class="text mb-3" v-if="order.payment.type === 'cod'"> Contrassegno </p>
            </div>
          </div>
          <hr>
          <div class="row">
            <!--<div class="col-lg-6 col-12 mt-3 mb-3 text-center">
              <router-link :to="'/payment/' + order.idorder" v-if="order.status === 'pending'" class="primary-btn pay-btn"> PAGA ORA</router-link>
            </div>-->
            <div class="col-lg-6 col-12 mt-3 mb-3 text-center">
              <router-link :to="'/ordersummary/' + order.idorder" class="primary-btn"> VEDI ORDINE </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center my-5" v-else>
      <h4 class="title">ORDINI</h4>
        <p class="text mt-3 mb-3"> Non hai ancora eseguito un ordine. </p>
        <router-link to="/commerce" class="primary-btn gotoshop">VAI ALLO SHOP</router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OrdiniProfilo",
  data() {
    return {
      allorders: [],
    }
  },
  methods: {
    async getOrders() {
      this.$store.commit('showSpinner');
      let token = localStorage.getItem("token");
      await axios.get("orders",
          {headers: {Authorization: `Bearer ${token}`}}
      ).then((resp) => {
        this.allorders = resp.data.orders;
        console.log(this.allorders);
      }).catch((error) => {
        console.log(error);
      })
      this.$store.commit('hideSpinner');
    },
    formatDate(value) {
      return value.substr(0, 10);
    }
  },
  mounted() {
    this.getOrders();
  }
}
</script>

<style scoped>
.singlebox {
  box-shadow: 0 4px 16px -2px rgb(215, 215, 217);
  padding: 4% 3% 2% 3%;
  margin: 20px 10px 0 10px;
}

.subtitle {
  font-style: normal;
}

.text-bigger {
  font-family: opensans;
}

.gotoshop {
  width: 25%;
}

.topcard {
  padding: 0 15px 0 15px;
}

p {
  margin-bottom: 0;
}

.primary-btn {
  width: 90%;
  font-size: 20px;
}


@media (max-width: 576px) {
  .subtitle {
    font-size: 25px;
  }

  .title-p {
    margin: 20px 0;
  }

  .gotoshop {
    width: 75%;
  }

}

</style>