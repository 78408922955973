<template>
  <div class="container">
    <p class="text-bigger text-center b-0 text-uppercase" v-bind:key="cat.id" v-for="cat in cats"> {{ cat.name }} </p>
    <p class="title text-center"> {{ postTitle }}</p>
    <p class="content text-left" v-html="postContent"></p>
    <p class="post_tags mb-3 text-uppercase" v-bind:key="tag.id" v-for="tag in tags"> # {{ tag.name }} </p>
  </div>
</template>

<script>
import axios from "axios";
import {WP_BLOG} from "@/constants/constants";

export default {
  name: "SinglePost",
  data() {
    return {
      postID: null,
      tagsID: [],
      tags: [],
      catID: [],
      cats: [],
      postTitle: null,
      postContent: null,
      post: [],
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.postID = this.$route.params.id;
    this.getPost(this.postID);
  },
  methods: {
    async getPost(id) {
      await axios
          .get(WP_BLOG + "posts", {
            params: {
              "include": id,
            }
          })
          .then(response => {
            this.post = response.data[0];
            this.postTitle = this.post.title.rendered;
            this.postContent = this.post.content.rendered;
            this.catID = this.post.categories;
            this.tagsID = this.post.tags;
            this.getCatsByID(this.catID);
            this.getTagsByID(this.tagsID);
          })
    },
    async getCatsByID(id) {
      await axios
          .get(WP_BLOG + "categories", {
            params: {
              "include": id,
            }
          })
          .then(response => {
            this.cats = response.data;
          })
    },
    async getTagsByID(id) {
      await axios
          .get(WP_BLOG + "tags", {
            params: {
              "include": id,
            }
          })
          .then(response => {
            this.tags = response.data;
          })
    }
  }
}
</script>

<style scoped>

.content, .post_tags {
  max-width: 800px;
  margin: 0 auto;
}

:global(ul li) {
  margin-left: 18px;
}


:global(p span) {
  font-family: opensans;
}

.post_tags {
  color: grey;
}

@media (max-width: 576px) {
  .text-bigger {
    margin-top: 30px;
  }

  span {
    display: none;
  }
}
</style>