<template>
  <section>
    <div class="container">
      <div class="col-lg-12">
        <p class="title title-p text-center">REGISTRAZIONE</p>
        <div class="alert alert-danger mt-3" v-if="errors.length > 0" v-for="error in errors">
          {{ error }}
        </div>
        <p class="text text-center mb-4">
          Compila gli spazi qui sotto per completare la registrazione; facendolo i tuoi dati rimarranno
          salvati nel tuo account così ogni volta che vorrai fare un ordine non dovrai riscriverli tutte le volte! <br><br>
          Potrai visualizzare tutti gli ordini che hai fatto nella sezione del tuo profilo STORICO ORDINI, così da avere
          sempre sotto mano tutti i capi che hai acquistato. <br>
          Se hai dei buoni regalo li potrai visualizzare sempre, così non ti dimentichi di usarli (i buoni non hanno
          scadenza).
          Inoltre se trovi un prodotto che ti piace ma è esaurita la tua taglia non ti devi preoccupare perché con la tua
          mail
          potrai essere aggiornata in caso venga reinserita!
        </p>
          <form @submit.prevent="checkForm()">
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <p class="text">Nome<span>*</span></p>
                  <input class="form-control" type="text" required v-model="user.name">
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <p class="text">Cognome<span>*</span></p>
                  <input class="form-control" type="text" required v-model="user.surname">
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <p class="text">Email<span>*</span></p>
                  <input class="form-control" type="email" required v-model="user.email">
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <p class="text">Password<span>*</span></p>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" v-if="seePwd" v-model="user.password">
                    <input type="password" class="form-control" v-else v-model="user.password">
                    <div class="input-group-append">
                      <button class="btn btn-dark bg-black icon-pwd" v-on:click="togglePassword" v-if="seePwd" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                          <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                        </svg>
                      </button>
                      <button class="btn btn-dark bg-black icon-pwd" v-on:click="togglePassword" v-else type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
                          <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <p class="text">Ripeti Password<span>*</span></p>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" v-if="seePwd" v-model="user.confpass">
                    <input type="password" class="form-control" v-else v-model="user.confpass">
                    <div class="input-group-append">
                      <button class="btn btn-dark bg-black icon-pwd" v-on:click="togglePassword" v-if="seePwd" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                          <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                        </svg>
                      </button>
                      <button class="btn btn-dark bg-black icon-pwd" v-on:click="togglePassword" v-else type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
                          <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <p class="text text-center pt-3 pb-3">
                  Già che ci siamo inserisci anche le tue misure, così abbiamo un tuo quadro completo:
                </p>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <p class="text">Altezza in cm<span>*</span></p>
                  <input class="form-control" type="text" required v-model="user.heightcm">
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <p class="text">Circonferenza seno in cm<span>*</span></p>
                  <input class="form-control" type="text" required v-model="user.breastcm">
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <p class="text">Circonferenza glutei in cm<span>*</span></p>
                  <input class="form-control" type="text" required v-model="user.sitcm">
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <p class="text">Punto vita in cm<span>*</span></p>
                  <input class="form-control" type="text" required v-model="user.waistcm">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="text-center mb-5 mt-4">
                  <p class="text mb-4">
                    Ora non ti resta che dare un’occhiata ai nostri look in stile CASUAL CHIC! Comoda e femminile SEMPRE!
                  </p>
                  <!--<p class="text">Se hai già scaricato l’app LA MORA GLAMOUR e ti sei registrata, utilizza la
                    stessa mail e password dell’app e accedi da
                    <router-link to="/login" class="link registrati">QUI.</router-link>
                  </p>-->
                  <button type="submit" class="secondary-btn">
                    Registrati
                  </button>
                </div>
              </div>
            </div>
          </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import router from "@/router";
import Swal from "sweetalert2";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "SignUp",
  data() {
    return {
      user: {
        name: '',
        surname: '',
        email: '',
        password: '',
        confpass: '',
        heightcm: '',
        breastcm: '',
        sitcm: '',
        waistcm: ''
      },
      errors: [],
      seePwd: false
    }
  },
  methods: {
    checkForm() {
      this.errors = [];
      if (!this.user.name || !this.user.surname || !this.user.email || !this.user.password || !this.user.confpass
          || !this.user.heightcm || !this.user.breastcm || !this.user.sitcm || !this.user.waistcm) {
        this.errors.push('Non hai inserito uno o più campi obbligatori.');
        window.scrollTo(0, 0);
      }
      if (this.user.password !== this.user.confpass) {
        this.errors.push('Le due password non corrispondono, riprovare.');
        window.scrollTo(0, 0);
      }
      if (this.errors.length === 0) {
        this.doSignup();
      }
    },
    async doSignup() {
      this.$store.commit('showSpinner');
      await axios.post("/signup", {
        name: this.user.name,
        surname: this.user.surname,
        email: this.user.email,
        password: this.user.password,
        heightcm: this.user.heightcm,
        breastcm: this.user.breastcm,
        sitcm: this.user.sitcm,
        waistcm: this.user.waistcm,
      }).then((res) => {
        //set expire
        localStorage.setItem('expires', Math.floor(Date.now() / 1000) + res.data.expires_in);
        //set token
        localStorage.setItem("token", res.data.token);
        //set refreshtoken
        localStorage.setItem("refreshtoken", res.data.refreshtoken);
        //set user
        localStorage.setItem("user", JSON.stringify(res.data.user));
        //this.$store.dispatch("signup", this.user);
        this.$store.commit("SET_AUTH", true);
        this.$store.commit('hideSpinner');
        router.push('/');
      }).catch((error) => {
        this.$store.commit('hideSpinner');
        console.log(error);
        if (error.response.status === 400) {
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "La mail inserita è già stata utilizzata.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
          });
        } else {
          console.log(error);
        }
      })
    },
    togglePassword() {
      this.seePwd ? this.seePwd = false : this.seePwd = true;
    }
  }
}
</script>

<style scoped>

.bg-black{
  background-color: #000;
}

.icon-pwd svg{
  fill: #ffffff;
}

.checkout__input p {
  font-size: 18px;
}

.text span{
  margin-left: 5px;
  color: #e20036;
}

@media (max-width: 576px) {
  .title-p {
    padding-top: 30px !important;
  }
}
</style>