<template>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="shopping__cart__table" v-if="cartProds > 0">
            <p class="title title-p text-center mb-0">CARRELLO</p>
            <table>
              <tbody>
                <tr v-for="cartProduct in cart.lineItems">
                  <td class="img-width">
                    <router-link :to="'/prodotto/' + cartProduct.idProduct">
                      <img v-if="cartProduct.product.image" :src="cartProduct.product.image" alt="">
                      <img v-else :src="base + 'img/placeholder.png'" alt=""/>
                    </router-link>
                  </td>
                  <td class="text-center" style="width: 50%; padding: 0 10px;">
                    <router-link :to="'/prodotto/' + cartProduct.idProduct" class="nolink">
                      <p class="text-bigger">{{ cartProduct.product.name }}</p> <br>
                      <p class="pg"> PREZZO:
                        <span class="text-bigger" v-if="cartProduct.product.discountPrice"> <s>{{ formatPrice(cartProduct.product.unitPrice * cartProduct.qnt) }} €</s> - {{ formatPrice(cartProduct.product.discountPrice * cartProduct.qnt) }} € </span>
                        <span class="text-bigger" v-else> {{ formatPrice(cartProduct.product.unitPrice * cartProduct.qnt) }} € </span>
                      </p>
                      <p class="pg"> QUANTITÀ: <span class="text-bigger"> {{ cartProduct.qnt }}</span> </p>
                    </router-link>
                  </td>
                  <!--<td style="width: 30%">
                    <p class="qnt text text-center">{{ cartProduct.qnt }} </p>
                  </td>-->
                  <td style="width: 8%">
                    <a class="close_button d-flex justify-content-center"
                       v-on:click="removeFromCart(cartProduct.idProduct, cartProduct.idVariant)">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6
                      105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8
                      12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                      </svg>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-if="cart.totals" class="pg text-center mt-3 mb-3">TOTALE: <span class="text-bigger">{{formatPrice(cart.totals.total)}} €</span></p>
            <div class="row text-center">
              <div class="col-lg-6 col-6">
                <router-link class="primary-btn" to="/commerce">Torna allo shop</router-link>
              </div>
              <div class="col-lg-6 col-6">
                <router-link to="/checkout" v-if="$store.state.totalProdsCart > 0" class="primary-btn empty">Vai al pagamento</router-link>
              </div>
            </div>
            <div class="text-center mt-3 emptycart">
            <a v-on:click="emptyCart">Svuota carrello</a>
            </div>
          </div>
          <div v-else class="text-center">
            <p class="title title-p mb-0"> CARRELLO </p>
            <p class="text-bigger mb-3"> Non ci sono prodotti nel carrello. </p>
              <router-link class="primary-btn" to="/commerce">Torna allo shop</router-link>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {IVA, PATH_APP} from "@/constants/constants";
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Cart",
  data() {
    return {
      idcart: localStorage.getItem("cartIdLaMora"),
      cart: [],
      base: PATH_APP,
    }
  },
  computed: {
    userCountry(){
      return this.$store.getters.getUserCountry;
    },
    cartProds() {
      return this.$store.getters.getCartTotalProducts;
    },
    auth() {
      return this.$store.getters.getAuth;
    }
  },
  methods: {
    async getCart() {
      this.$store.commit("showSpinner");
      let idcart = localStorage.getItem("cartIdLaMora");
      let token = localStorage.getItem("token");
      await axios.get("cart?idcart=" + idcart, {
        headers: {Authorization: `Bearer ${token}`}
      }).then((resp) => {
        this.$store.commit('hideSpinner');
        this.cart = resp.data.cart;
        if (!localStorage.getItem("cartIdLaMora")) {
          this.$store.commit('hideSpinner');
          localStorage.setItem("cartIdLaMora", resp.data.cart.id);
        }
        this.$store.commit('SET_TOTAL_PRODSCART', resp.data.totalProdsCart);

      }).catch((error) => {
        this.$store.commit('hideSpinner');
        if (error.response.status === 404) {
          localStorage.removeItem("cartIdLaMora");
        } else {
          console.log(error);
        }
      });
      this.$store.commit('hideSpinner');
    },
    formatPrice(value) {
      if(this.userCountry && (this.userCountry === "Svizzera" || this.userCountry === "Regno Unito (UK)")){
        //value = (value - ((value / 100) * IVA)).toFixed(2);
        value = (value / 1.22).toFixed(2);
      }
      return Number(value / 100)
          .toFixed(2)
          .replace(".", ",");
    },
    async removeFromCart(idprod, idvar) {
      let idcart = localStorage.getItem("cartIdLaMora");
      if (idcart !== null) {
        let token = localStorage.getItem("token");
        await axios.delete("removefromcart", {
          headers: {Authorization: `Bearer ${token}`},
          data: {
            idCart: idcart,
            idProduct: idprod,
            idVariant: idvar,
          }
        }).then((resp) => {
          this.cart = resp.data.cart;
          this.$store.commit('SET_TOTAL_PRODSCART', resp.data.totalProdsCart);
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    async emptyCart() {
      let idcart = localStorage.getItem("cartIdLaMora");
      let token = localStorage.getItem("token");
      await axios.delete("emptycart", {
        headers: {Authorization: `Bearer ${token}`},
        data: {
          idCart: idcart,
        }
      }).then((resp) => {
        this.cart = resp.data.cart;
        this.idcart = null;
        this.$store.commit('SET_TOTAL_PRODSCART', 0);
        localStorage.removeItem("cartIdLaMora");
      }).catch((error) => {
        this.$store.commit('SET_TOTAL_PRODSCART', 0);
        localStorage.removeItem("cartIdLaMora");
        console.log(error);
      });

    }
  },
  mounted() {
    window.scrollTo(0,0);
    this.getCart();
  }
}
</script>

<style scoped>

.primary-btn {
  text-align: center;
}

.emptycart {
  cursor: pointer;
  font-size: 15px;
  color: #c4c4c4;
  font-family: opensans-light;
}

.emptycart a {
  color: #c4c4c4;
  text-decoration-line: underline;
  -webkit-text-decoration-line: underline;
  text-decoration-color: #c4c4c4;
  -webkit-text-decoration-color: #c4c4c4;
}

.img-width {
  width: 8%;
}

.shopping__cart__table table tbody tr td {
  margin-right: 0;
}

svg {
  width: 30px;
}

.shopping__cart__table table thead tr th, .cart__discount h6, .cart__total h6 {
  color: black;
  font-weight: 700;
  font-size: 19px;
  text-transform: uppercase;
}

.empty {
  color: #fefefa;
  background: #94705e;
}

.close_button {
  cursor: pointer;
}

.imgname h6 {
  margin: auto;
}

.text-bigger, .pg {
  margin-bottom: 0;
}

.nolink{
  text-decoration: none;
}

/* mobile */
@media only screen and (max-width: 576px) {
  .title-p {
    padding-top: 30px !important;
  }

  .img-width {
    width: 25%;
  }

  .text-bigger {
    font-size: 17px;
    margin-bottom: 0;
  }

  svg {
    width: 20px;
  }


}

</style>