<template>
  <div class="shop-button" v-if="scrolled && this.$route.name === 'Home'">
    <router-link to="/commerce">SHOP</router-link>
  </div>
  <div class="scroll-top" v-on:click="scrollToTop" v-if="scrolled">
    <font-awesome-icon icon="angle-up" size="lg"/>
  </div>
</template>
<script>
export default {
  name: 'ScrollTop',
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      scrolled: false
    }
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
</script>

<style scoped>

.shop-button {
  background-color: black;
  position: fixed;
  font-family: oswald;
  font-size: 25px;
  padding-top: 5px;
  width: 75%;
  left: 20px;
  height: 50px;
  bottom: 16px;
  text-align: center;
  border: 1px solid #3d3d3d;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  visibility: visible;
  display: none;
  color: #ffffff;
}

.scroll-top {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  position: fixed;
  bottom: 16px;
  right: 20px;
  z-index: 9999;
  color: #0b0b0b;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.shop-button a{
  color: #ffffff;
}

.scroll-top:hover {
  cursor: pointer;
}

.scroll-top svg {
  position: absolute;
  top: 15px;
  left: 16px;
}

@media (max-width: 576px) {
  .shop-button{
    display:block;
  }
}

</style>
